import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiLockIcon from "@mui/icons-material/WifiLock";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

const renderCellContent = (params) => {
  const hasNoData = params.value === "No data";
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      {params.value}
      {hasNoData && <ReportProblemIcon style={{ color: "grey" }} />}
    </div>
  );
};

const DataGridTable = ({ data, loading }) => {
  const columns = [
    {
      field: "organizationName",
      headerName: "Organization",
      width: 200,
      //headerAlign: 'center',
      //align: 'right',
      renderCell: renderCellContent,
    },
    {
      field: "outletName",
      headerName: "Name",
      width: 200,
      //headerAlign: 'center',
      //align: 'center',
      renderCell: renderCellContent,
    },
    {
      field: "outletID",
      headerName: "Device ID",
      width: 200,
      //headerAlign: 'center',
      //align: 'center',
      renderCell: renderCellContent,
    },
    {
      field: "outletStatus",
      headerName: "Status",
      width: 100,
      //headerAlign: 'center',
      //align: 'center',
      renderCell: (params) => {
        if (params.value === "No data") {
          return renderCellContent(params);
        }
        switch (params.value) {
          case "On":
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {params.value} <ToggleOnIcon style={{ color: "green" }} />
              </div>
            );
          case "Off":
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {params.value} <ToggleOffIcon style={{ color: "red" }} />
              </div>
            );
          default:
            return renderCellContent;
        }
      },
    },
    {
      field: "lastOffUpdate",
      headerName: "Last Off Update",
      width: 200,
      //headerAlign: 'center',
      //align: 'center',
      renderCell: renderCellContent,
    },
    {
      field: "lastOnUpdate",
      headerName: "Last On Update",
      width: 200,
      //headerAlign: 'center',
      //align: 'center',
      renderCell: renderCellContent,
    },
    {
      field: "lastDataUpdate",
      headerName: "Last Data Update",
      width: 200,
      //headerAlign: 'center',
      //align: 'center',
      renderCell: renderCellContent,
    },
    {
      field: "wifiStatus",
      headerName: "WiFi Status",
      width: 200,
      //headerAlign: 'center',
      //align: 'center',
      renderCell: (params) => {
        if (params.value === "No data") {
          return renderCellContent(params);
        }
        switch (params.value) {
          case "Connected":
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {params.value} <WifiIcon style={{ color: "green" }} />
              </div>
            );
          case "Disconnected":
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {params.value} <WifiOffIcon style={{ color: "red" }} />
              </div>
            );
          default:
            return (
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                {params.value} <WifiLockIcon style={{ color: "grey" }} />
              </div>
            );
        }
      },
    },
  ];

  const rows = data.flatMap((outlet) => ({
      id: `${outlet.outletId}`,
      organizationName: outlet.orgName || "No data",
      outletName: outlet.name || "No data",
      outletStatus: outlet.status || "No data",
      lastOffUpdate: outlet.lastOffUpdate|| "No data",
      lastOnUpdate: outlet.lastOnUpdate || "No data",
      lastDataUpdate: outlet.lastDataUpdate || "No data",
      wifiStatus: outlet.wifiStatus || "No data",
      outletID: outlet.id || "No data",
    }));
  
  /*data.flatMap((org) =>
    org.outlets.map((outlet) => ({
      id: `${org.organizationId}-${outlet.outletId}`,
      organizationName: org.name || "No data",
      outletName: outlet.name || "No data",
      outletStatus: outlet.status || "No data",
      lastOffUpdate: outlet.lastOffUpdate || "No data",
      lastOnUpdate: outlet.lastOnUpdate || "No data",
      lastDataUpdate: outlet.lastDataUpdate || "No data",
      wifiStatus: outlet.wifiStatus || "No data",
    }))*/

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        sx={{ width: "90%" }}
        getRowHeight={() => 'auto'}
        initialState={{
          sorting: {
            sortModel: [{ field: 'organizationName', sort: 'asc' }],
          },
        }}
      />
    </Box>
  );
};

export default DataGridTable;
