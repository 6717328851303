import React, { useState, useEffect } from "react";

import moment from "moment";
import LifetimeSavingsPresentational from "./lifetimeSavingsPresentational";
import {
  leftSavingsPress,
  rightSavingsPress,
  // fetchOutletSavings,
} from "../../../../functions/outletList";
import LocationBubbles from "../../../../components/locationBubble";
import IonIcon from "@reacticons/ionicons";
import { greyLight } from "../../../../lib/colors";
import {
  fetchLifetimeSavings,
  fetchMonthlySavings,
} from "../../../../functions/savings";
import { Typography } from "@mui/material";

export default function SavingsContainer({
  orgID,
  outlets,
  rate,
  height,
  width,
  outletID = null,
}) {
  const [savingsDisplayIndex, setSavingsDisplayIndex] = useState(0);
  //needs to be updated for adding additional savings display options
  const savingsDisplayLength = 2;
  const [monthlyCO2eSavings, setMonthlyCO2eSavings] = useState([]);
  const [monthlyCostSavings, setMonthlyCostSavings] = useState([]);
  const [monthlyEnergySavings, setMonthlyEnergySavings] = useState([]);
  const [lifeCO2eSavings, setLifeCO2eSavings] = useState([]);
  const [lifeCostSavings, setLifeCostSavings] = useState([]);
  const [lifeEnergySavings, setLifeEnergySavings] = useState([]);

  const year = moment().year().toString();
  const month = moment().format("L").substring(0, 2);

  useEffect(() => {
    if (outletID) {
      fetchMonthlySavings(
        orgID,
        setMonthlyCO2eSavings,
        setMonthlyCostSavings,
        setMonthlyEnergySavings,
        year,
        month,
        rate,
        outletID
      );
    } else {
      fetchMonthlySavings(
        orgID,
        setMonthlyCO2eSavings,
        setMonthlyCostSavings,
        setMonthlyEnergySavings,
        year,
        month,
        rate,
      );
    }
  }, [month, orgID, outlets, year]);
  useEffect(() => {
    fetchLifetimeSavings(
      orgID,
      setLifeCO2eSavings,
      setLifeCostSavings,
      setLifeEnergySavings,
      rate,
    );
  }, [orgID]);

  const handleLeftSavingsPress = () => {
    leftSavingsPress(
      savingsDisplayIndex,
      savingsDisplayLength,
      setSavingsDisplayIndex
    );
  };
  const handleRightSavingsPress = () => {
    rightSavingsPress(
      savingsDisplayIndex,
      savingsDisplayLength,
      setSavingsDisplayIndex
    );
  };

  const savingsBlockStyle = {
    backgroundColor: greyLight,
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "40%",
    alignItems: "center",
  };

  let organizationMonthlySavings = [
    { co2eSavings: monthlyCO2eSavings },
    { costSavings: monthlyCostSavings },
    { energySavings: monthlyEnergySavings },
  ];

  /*Filter the savings by appliance type*/
  if(monthlyEnergySavings.length !== 0){
    let keysToCheck = [];
    outlets.forEach((obj) => {
      keysToCheck.push(obj.id)
    });

    // Filter objects to include only those with at least one of the keys
    const filteredMonthlyEnergyArray = monthlyEnergySavings.filter(obj => 
      keysToCheck.some(key => obj.hasOwnProperty(key))
    );
    const filteredMonthlyCO2eArray = monthlyCO2eSavings.filter(obj => 
      keysToCheck.some(key => obj.hasOwnProperty(key))
    );
    const filteredMonthlyCostArray = monthlyCostSavings.filter(obj => 
      keysToCheck.some(key => obj.hasOwnProperty(key))
    );
    organizationMonthlySavings = [
      { co2eSavings: filteredMonthlyCO2eArray },
      { costSavings: filteredMonthlyCostArray },
      { energySavings: filteredMonthlyEnergyArray },
    ];
  }

  const organizationSavings = [
    { co2eSavings: lifeCO2eSavings },
    { costSavings: lifeCostSavings },
    { energySavings: lifeEnergySavings },
  ];

  if (outletID) {
    return (
      <div style={savingsBlockStyle}>
        {monthlyCO2eSavings.length !== 0 &&
        monthlyEnergySavings.length !== 0 &&
        savingsDisplayIndex === 0 ? (
          <div style={{ width: "100%", height: "80%" }}>
            <p
              style={{
                fontWeight: "600",
                fontSize: width < 1000 ? width*0.02 : "20px",
                fontFamily: "manrope",
                marginTop: "2%",
                marginLeft: "4%",
              }}
            >
              Monthly Savings
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <LifetimeSavingsPresentational
                organizationSavings={organizationMonthlySavings}
                width={width}
                height={height}
                monthly={true}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography
              style={{
                fontSize: width < 1000 ? width*0.025 : "24px",
                textAlign: "center",
                fontFamily:"manrope"
              }}
            >
              Implement a schedule to receive savings
            </Typography>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div style={savingsBlockStyle}>
        {savingsDisplayIndex === 0 ? (
          <div style={{ width: "100%", height: "80%" }}>
            <p
              style={{
                fontWeight: "600",
                fontSize: width < 1000 ? "2vw" : "20px",
                fontFamily: "manrope",
                marginTop: "2%",
                marginLeft: "4%",
              }}
            >
              Monthly Savings
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <LifetimeSavingsPresentational
                organizationSavings={organizationMonthlySavings}
                width={width}
                height={height}
                monthly={true}
              />
            </div>
          </div>
        ) : (
          <div style={{ width: "100%", height: "80%" }}>
            <p
              style={{
                fontWeight: "600",
                fontSize: width < 1000 ? "2vw" : "20px",
                fontFamily: "manrope",
                marginTop: "2%",
                marginLeft: "4%",
              }}
            >
              Lifetime Savings
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {organizationSavings[0].co2eSavings.length !== 0 &&
                organizationSavings[1].costSavings.length !== 0 &&
                organizationSavings[2].energySavings.length !== 0 && (
                  <LifetimeSavingsPresentational
                    organizationSavings={organizationSavings}
                    width={width}
                    height={height}
                    monthly={false}
                  />
                )}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            height: "20%",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              //marginTop: '-1%',
              height: "65%",
              width: "100%",
              justifyContent: "center",
              //backgroundColor:'green',
            }}
          >
            <IonIcon
              name="arrow-back-circle-outline"
              style={{
                width: width < 1400 ? width*0.03 : "40px",
                cursor: "pointer",
              }}
              onClick={handleLeftSavingsPress}
            />
            <IonIcon
              name="arrow-forward-circle-outline"
              style={{
                width: width < 1400 ? width*0.03 : "40px",
                cursor: "pointer",
              }}
              onClick={handleRightSavingsPress}
            />
          </div>
          <div
            style={{
              height: "35%",
              marginBottom: '5px'
            }}
          >
            <LocationBubbles
              activeIndex={savingsDisplayIndex}
              displayLength={savingsDisplayLength}
              width={width}
            />
          </div>
        </div>
      </div>
    );
  }
}
