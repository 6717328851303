import React from "react";
import IonIcon from "@reacticons/ionicons";
import { green } from "../../lib/colors";

export const GraphHeader = ({ percentChange, type, weeklyAverage, width }) => {
    let legend;
    if (type == 'weekly') {
        legend = "-------- Average Weekly Usage"
    } else if (type == 'daily') {
        legend = "-------- Average Daily Usage"
    } else {
        legend = "-------- Average Hourly Usage"
    }
    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '1.5%',
            justifyContent: 'space-evenly'
        }}>
            <div style={{
                display: 'flex',
                width: '50%',
                alignItems: 'center',
            }}>
                {weeklyAverage > 0 &&
                    <div style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                    }}>
                        <p style={{
                            color: percentChange === 0 ? 'black' : (percentChange < 0 ? green : 'red'),
                        }}
                        >
                            {percentChange === 0 ?
                                <IonIcon
                                    name="swap-horizontal-outline"
                                    style={{
                                        width: width < 1250 ? '2vw' : '25px',
                                    }}
                                />
                                : (percentChange < 0 ?
                                    <IonIcon
                                        name="arrow-down-outline"
                                        style={{
                                            width: width < 1250 ? '2vw' : '30px',
                                        }}
                                    />
                                    :
                                    <IonIcon
                                        name="arrow-up-outline"
                                        style={{
                                            fontSize: width < 1250 ? width*.02 : '25px',
                                        }}
                                    />)
                            }
                        </p>
                        <p style={{
                            fontFamily: 'manrope',
                            marginLeft: '1%',
                            fontSize: width < 1250 ? width*.015 : '20px',
                            color: percentChange === 0 ? 'black' : (percentChange < 0 ? green : 'red')
                        }}>
                            {percentChange}% from previous week
                        </p>
                    </div>
                }
            </div>
            <div style={{
                display: 'flex',
                width: type == "hourly" ? '80%' : '50%',
                height: width < 1050 ? width*.02 : '30px',
                alignItems: 'center',
                justifyContent: 'end',
                marginRight: '1%',
            }}>
                <p style={{
                    // marginLeft: '20%',
                    textAlign: 'center',
                    fontSize: width < 1250 ? width*.015 : '20px',
                    fontFamily: 'manrope',
                }}>
                    {legend}
                </p>
            </div>
        </div>
    );
};