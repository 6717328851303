import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
} from "@mui/material";
import { green, greyUltraDark, orange } from "../../lib/colors";
import ScheduleDialog from "../scheduleDialog";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const HoursTable = ({ schedules, selectedDay, outlets }) => {
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const [filteredSchedules, setFilteredSchedules] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  useEffect(() => {
    // Filter schedules whenever schedules or selectedDay changes
    const newFilteredSchedules = schedules.filter(
      (schedule) =>
        schedule.repeat.includes(selectedDay) && schedule.status !== "Rejected"
    );
    setFilteredSchedules(newFilteredSchedules);
  }, [schedules, selectedDay]);

  const handleRowClick = (schedule) => {
    setSelectedSchedule(schedule);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedSchedule(null);
  };

  const handleDeleteSchedule = (scheduleToDelete) => {
    // Filter out the deleted schedule
    const updatedSchedules = filteredSchedules.filter(
      (schedule) => schedule !== scheduleToDelete
    );
    setFilteredSchedules(updatedSchedules);
  };

  const handleStatusChange = (updatedSchedule) => {
    const updatedSchedules = filteredSchedules.map((schedule) =>
      schedule.id === updatedSchedule.id ? updatedSchedule : schedule
    );
    setFilteredSchedules(updatedSchedules);
  };

  const formatHour = (hour) => {
    const period = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${formattedHour}:00 ${period}`;
  };

  const formatHeight = (schedule) => {
    return "93px"; // Set a fixed height for now, since it is not working for the first 2
  };

  // const formatHeight = (schedule) => {
  // const baseHeight = 30; // minimum height
  // const heightPerOutlet = 10;
  // const calculatedHeight = baseHeight + (heightPerOutlet * schedule.outletsSelected.length);
  // return `100%`;
  // return `${calculatedHeight}px`;
  // };
  //console.log(outlets)

  // let productTypes_arr = []
  // filteredSchedules.map((schedule, index) => {
  //   schedule.outletsSelected).map((outlet) => {
  //     productTypes_arr.push(outlets.find(obj => obj.id === outlet).productType)
  //   }
  //   let productTypes = [...new Set(productTypes_arr)]
  // }
  // )

  let productTypes_arr = []
  filteredSchedules.map((schedule, index) => {
    let productTypes = []
    schedule.outletsSelected.map(outlet => {
      productTypes.push(outlets.find(obj => obj.id === outlet).productType)
    })
    productTypes = [...new Set(productTypes)]
    productTypes_arr.push(productTypes)
  });

  return (
    <>
      <TableContainer component={Paper} sx={{
        marginRight: 'auto',
        marginLeft: 'auto',
        width: '100%',
      }}>
        <Table
          sx={{
            // minWidth: 450,
            // width: "90vw",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                key={0}
                size = "small"
                padding = "normal"
                sx={{
                  borderRight: 1,
                  borderColor: 'divider',
                  //minWidth: '1px',
                  //width:'.01vw'
                  fontSize:'1vw'
                }}
              >
                Product Types
              </TableCell>
              {hours.map((hour) => (
                <TableCell
                  align="center"
                  key={hour+1}
                  size = "small"
                  padding = "none"
                  sx={{
                    borderRight: 1,
                    borderColor: 'divider',
                    //minWidth: '1px',
                    //width:'.01vw'
                    fontSize:'1vw'
                  }}
                >
                  {formatHour(hour)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSchedules.map((schedule, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(schedule)}
                style={{
                  cursor: "pointer",
                  height: formatHeight(schedule),
                }}
              >
                <TableCell
                  align="center"
                  key={0}
                  size = "small"
                  padding = "normal"
                  sx={{
                    borderRight: 0,
                    //borderColor: 'white',
                    fontSize:'1vw'
                  }}
                >

                {productTypes_arr[index].map(productType => (
                  <div>{productType}</div>
                ))}
              </TableCell>
                {renderScheduleCells(schedule)}
              </TableRow>
            ))}
            {filteredSchedules.length === 0 && (
              <TableRow>
                <TableCell colSpan={24} align="center">
                  No schedules for {selectedDay}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ScheduleDialog
        open={open}
        handleClose={handleClose}
        schedule={selectedSchedule}
        formatHour={formatHour}
        onDelete={handleDeleteSchedule}
        onStatusChange={handleStatusChange}
      />
    </>
  );
};

function computeTimeSpans(onHour, offHour, totalHours = 24, status) {
  const spans = [];
  let currentColor = status === "New" ? orange : greyUltraDark;
  let currentStart = 0;

  for (let hour = 0; hour < totalHours; hour++) {
    const isOn = hour >= onHour && hour < offHour;
    const newColor = isOn ? green : status === "New" ? orange : greyUltraDark;

    if (newColor !== currentColor) {
      spans.push({
        start: currentStart,
        end: hour,
        color: currentColor,
      });
      currentStart = hour;
      currentColor = newColor;
    }
  }

  spans.push({
    start: currentStart,
    end: totalHours,
    color: currentColor,
  });

  return spans;
}

function renderScheduleCells(schedule) {
  const { onHour, offHour, outletsSelected, status } = schedule;
  const spans = computeTimeSpans(onHour, offHour, 24, status);

  return spans.map((span, index) => {
    const duration = span.end - span.start;
    const text =
      span.color === green
        ? 'ON'
        : '';

    // console.log(duration)
    return (
      <TableCell
        key={index}
        align="center"
        colSpan={duration}
        size = "small"
        padding = "none"
        style={{
          backgroundColor: span.color,
          fontSize: duration==1? '0px' : '1vw',
          borderRadius: '10px',
          //minWidth: `${(100 / 24) * duration}%`,
          //maxWidth: `${(100 / 24) * duration}%`,
        }}
      >
        {schedule.status === "New" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              justifyContent: "center",
            }}
          >
            <ErrorOutlineIcon></ErrorOutlineIcon>
            <Typography>Awaiting approval</Typography>
          </Box>
        )}
        {text}
      </TableCell>
    );
  });
}

export default HoursTable;
