import { doc, Firestore } from 'firebase/firestore';
import db from '../firebase';
import { uid } from '../firebase';

export const DEFAULT_STATE = {
  auth: { error: '', loading: false, isLoggedIn: false },
  user: {
    error: '',
    loading: false,
    data: {
      uid: '',
      email: '',
      name: '',
      zipcode: '',
      timeZoneOffset: 0,
      joinDate: null,
      rate: 0,
      region: '',
      teakCoins: 0,
      energySavings: 0,
      priceSavings: 0,
      co2Savings: 0,
    },
  },
  outlets: { error: '', loading: false, data: [] },
  usage: { error: '', loading: false, data: {} },
  idleLoad: { error: '', loading: false, data: {} },
  ecoStats: { error: '', loading: false, data: {} },
  emissions: { error: '', loading: false, data: {} },
  groups: { error: '', loading: false, data: [] },
  rules: { error: '', loading: false, data: [] },
  missions: { error: '', loading: false, data: [] },
  global: { error: '', loading: false, data: {} },
};

export function buildUser(userSnapshot) {
  const {
    email,
    // name,
    zipcode,
    timezoneOffset,
    joinDate,
    rate,
    region,
    teakCoins,
    energySavings,
    priceSavings,
    co2Savings,
  } = userSnapshot._document.data.value.mapValue.fields;

  if (zipcode != "") {
    return {
      error: '',
      loading: false,
      data: {
        email,
        // name,
        uid: userSnapshot.id,
        zipcode,
        timezoneOffset,
        joinDate,
        rate,
        region,
        teakCoins,
        energySavings,
        priceSavings,
        co2Savings,
      },
    };
  }
  else {
    return {
      error: '',
      loading: false,
      data: {
        email,
        uid: userSnapshot.id,
        timezoneOffset,
        joinDate,
        teakCoins,
        energySavings,
        priceSavings,
        co2Savings,
      },
    };
  }
}

//what data is needed/used out of this descturcture, looks different from what comes back from Outlets search
//might need subcollections searches here too
//need to add the filter based on user's id before adding to data array
export function buildOutlets(outletsSnapshot, userID) {
  const data = [];
  outletsSnapshot.docs.forEach((doc, index) => {
    const {
      device,
      deviceType,
      outletType,
      groupID,
      name,
      status,
      font,
      icon,
      priceSavings,
      energySavings,
      co2Savings,
      productType,
    } = doc._document.data.value.mapValue.fields;
    if (doc.ref._path.segments[6] == userID) {
      data.push({
        id: doc.id,
        index,
        device,
        deviceType,
        outletType,
        groupID,
        name,
        status,
        font,
        icon,
        priceSavings,
        energySavings,
        co2Savings,
        productType,
      });
    }
  });

  return { ...DEFAULT_STATE.outlets, data };
}
//not sure what this is supposed to be using
//will need to update function and filter
//needs to create an entry for each outlet
export function buildUsageData(
  outletsSnapshot,
  userID,
  wattHoursYears,
  wattHoursMonths,
  wattHoursWeeks,
  wattHoursDays,) {
  let data = {};
  const yearArr = [];
  const monthArr = [];
  const weekArr = [];
  const dayArr = [];

  const monthsData = [];
  const fixedYears = {};
  wattHoursYears.docs.forEach((doc) => {
    if (doc.ref._path.segments[8].length > 4 && doc.ref._path.segments[8].length < 13) {
      data[doc.ref._path.segments[8]] = { day: {}, week: {}, month: {}, year: {} };
    };
    if (
      doc.ref._path.segments[6] == userID &&
      doc.id.length == 4 &&
      doc.ref._path.segments[9] == 'WattHours') {
      const value = doc._document.data.value.mapValue.fields.wattHour.doubleValue;
      yearArr.push({ [doc.ref._path.segments[8]]: { [doc.id]: value } });
    };
    if (doc.ref._path.segments[6] == userID && doc.id.length == 4 && doc.ref._path.segments[9] == 'WattHours') {
      wattHoursMonths.docs.forEach(monthDoc => {
        if (monthDoc.ref._path.segments[6] == userID && monthDoc.ref._path.segments[9] == 'WattHours' && monthDoc.ref._path.segments[10] == doc.id) {
          monthsData.push({ [doc.id]: { [monthDoc.id]: monthDoc._document.data.value.mapValue.fields.wattHour } });
        };
      });
    };
  });
  //saving month totals into each year
  monthsData.forEach(obj => {
    const key = Object.keys(obj)[0];
    const value = obj[key];
    if (fixedYears[key]) {
      fixedYears[key].push(value);
    } else {
      fixedYears[key] = [value];
    };
  });
  const weeksData = [];
  const fixedMonths = {};
  wattHoursMonths.docs.forEach(doc => {
    if (doc.ref._path.segments[6] == userID && doc.ref._path.segments[9] == 'WattHours') {
      const value = doc._document.data.value.mapValue.fields.wattHour.doubleValue;
      monthArr.push({ [doc.ref._path.segments[8]]: { [doc.id]: value } });
    };
    if (doc.ref._path.segments[6] == userID && doc.ref._path.segments[9] == 'WattHours') {
      wattHoursWeeks.docs.forEach(weekDoc => {
        if (weekDoc.ref._path.segments[6] == userID && weekDoc.ref._path.segments[9] == 'WattHours' && weekDoc.ref._path.segments[12] == doc.id) {
          weeksData.push({ [doc.id]: { [weekDoc.id]: weekDoc._document.data.value.mapValue.fields.wattHour } })
        }
      })
    }
  });
  //saving week totals into each month
  weeksData.forEach(obj => {
    const key = Object.keys(obj)[0];
    const value = obj[key];
    if (fixedMonths[key]) {
      fixedMonths[key].push(value);
    } else {
      fixedMonths[key] = [value];
    };
  });
  const daysData = [];
  const fixedWeeks = {};
  wattHoursWeeks.docs.forEach(doc => {
    if (doc.ref._path.segments[6] == userID && doc.ref._path.segments[9] == 'WattHours') {
      const value = doc._document.data.value.mapValue.fields.wattHour.doubleValue;
      weekArr.push({ [doc.ref._path.segments[8]]: { [doc.id]: value } });
    };
    if (doc.ref._path.segments[6] == userID && doc.ref._path.segments[9] == 'WattHours') {
      wattHoursDays.docs.forEach(dayDoc => {
        if (dayDoc.ref._path.segments[6] == userID && dayDoc.ref._path.segments[9] == 'WattHours' && dayDoc.ref._path.segments[14] == doc.id) {
          daysData.push({ [doc.id]: { [dayDoc.id]: dayDoc._document.data.value.mapValue.fields.wattHour } });
        };
      });
    };
  });
  //saving day totals into each week
  daysData.forEach(obj => {
    const key = Object.keys(obj)[0];
    const value = obj[key];
    if (fixedWeeks[key]) {
      fixedWeeks[key].push(value);
    } else {
      fixedWeeks[key] = [value];
    };
  });
  wattHoursDays.docs.forEach(doc => {
    const hoursData = [];
    if (doc.ref._path.segments[6] == userID && doc.ref._path.segments[9] == 'WattHours') {
      for (const key in doc._document.data.value.mapValue.fields) {
        if (key != 'wattHour') {
          // console.log(wattHoursDays.docs[doc.id]._document.data.value.mapValue.fields[key]);
          hoursData.push({ [key]: doc._document.data.value.mapValue.fields[key] })
        }
      }
      hoursData.sort((a, b) => {
        const keyA = parseInt(Object.keys(a)[0].replace('hour_', ''));
        const keyB = parseInt(Object.keys(b)[0].replace('hour_', ''));

        return keyA - keyB;
      })
      // const value = doc._document.data.value.mapValue.fields.wattHour.doubleValue;
      dayArr.push({ [doc.ref._path.segments[8]]: { [doc.id]: hoursData } });
    };
  });
  let splitYearArrays = {};
  let splitMonthArrays = {};
  let splitWeekArrays = {};
  let splitDayArrays = {};
  //years, creates years object for outlet
  for (let item of yearArr) {
    let key = Object.keys(item)[0]; // Get the first key of each item
    if (key in splitYearArrays) {
      splitYearArrays[key].push(Object.values(item)[0]);
    } else {
      splitYearArrays[key] = [Object.values(item)[0]];
    }
  };
  //replaces years object with fixed object
  for (let key in splitYearArrays) {
    let splitYearArray = splitYearArrays[key];
    let fixedYearKeys = Object.keys(fixedYears);
    for (let i = 0; i < splitYearArray.length; i++) {
      const element = splitYearArray[i];
      for (let j = 0; j < fixedYearKeys.length; j++) {
        const yearsKey = fixedYearKeys[j];
        splitYearArray[i] = { [yearsKey]: fixedYears[yearsKey] };
        i++;
      };
      i += fixedYearKeys.length - 1;
    };
    data[key]['year'] = splitYearArrays[key];
  };
  //months, creates months object for outlet
  for (let item of monthArr) {
    let key = Object.keys(item)[0]; // Get the first key of each item
    if (key in splitMonthArrays) {
      splitMonthArrays[key].push(Object.values(item)[0]);
    } else {
      splitMonthArrays[key] = [Object.values(item)[0]];
    }
  };
  //replaces months object with fixed object
  for (let key in splitMonthArrays) {
    let splitMonthArray = splitMonthArrays[key];
    let fixedMonthKeys = Object.keys(fixedMonths);
    for (let i = 0; i < splitMonthArray.length; i++) {
      const element = splitMonthArray[i];
      for (let j = 0; j < fixedMonthKeys.length; j++) {
        const monthsKey = fixedMonthKeys[j];
        splitMonthArray[i] = { [monthsKey]: fixedMonths[monthsKey] };
        i++;
      };
      i += fixedMonthKeys.length - 1;
    };
    data[key]['month'] = splitMonthArrays[key];
  };
  //weeks, creates weeks object for outlet
  for (let item of weekArr) {
    let key = Object.keys(item)[0]; // Get the first key of each item
    if (key in splitWeekArrays) {
      splitWeekArrays[key].push(Object.values(item)[0]);
    } else {
      splitWeekArrays[key] = [Object.values(item)[0]];
    }
  };
  //replaces weeks object with fixed object
  for (let key in splitWeekArrays) {
    let splitWeekArray = splitWeekArrays[key];
    let fixedWeekKeys = Object.keys(fixedWeeks);
    for (let i = 0; i < splitWeekArray.length; i++) {
      const element = splitWeekArray[i];
      for (let j = 0; j < fixedWeekKeys.length; j++) {
        const weeksKey = fixedWeekKeys[j];
        splitWeekArray[i] = { [weeksKey]: fixedWeeks[weeksKey] };
        i++;
      };
      i+=fixedWeekKeys.length-1;
    };
    // console.log('week object',splitWeekArrays[key]);
    data[key]['week'] = splitWeekArrays[key];
  };
  //days, creates months object for outlet
  for (let item of dayArr) {
    let key = Object.keys(item)[0]; // Get the first key of each item
    if (key in splitDayArrays) {
      splitDayArrays[key].push(Object.values(item)[0]);
    } else {
      splitDayArrays[key] = [Object.values(item)[0]];
    }
  };
  for (let key in splitDayArrays) {
    data[key]['day'] = splitDayArrays[key];
  };
  // outletsSnapshot.forEach((doc) => {
  //   data[doc.id] = { day: {}, week: {}, month: {}, year: {} };
  // });
  console.log('data from state utils', data);
  return { ...DEFAULT_STATE.usageData, data };
}


export function buildGroups(groupsSnapshot, userID) {
  const data = [];
  groupsSnapshot.docs.forEach((doc, index) => {
    const { name, outlets } = doc._document.data.value.mapValue.fields;
    if (doc.ref._path.segments[6] == userID) {
      data.push({
        id: doc.id,
        index,
        name,
        outlets,
      });
    }
  });
  return { ...DEFAULT_STATE.groups, data };
}

function buildRules(schedulesSnapshot, timersSnapshot, userID) {
  const data = [];
  schedulesSnapshot.forEach((doc) => {
    const {
      id,
      outletsSelected,
      display,
      names,
      offHour,
      offMinute,
      offTime,
      onHour,
      onMinute,
      onTime,
      repeat,
      type,
      missionId,
    } = doc._document.data.value.mapValue.fields;
    if (doc.ref._path.segments[6] == userID) {
      data.push({
        id,
        outletsSelected,
        display,
        names,
        offHour,
        offMinute,
        offTime,
        onHour,
        onMinute,
        onTime,
        repeat,
        type,
        missionId,
      });
    }
  });

  timersSnapshot.docs.forEach((doc) => {
    const {
      id,
      outletsSelected,
      names,
      type,
      status,
      hours,
      minutes,
      tomorrow,
      time,
    } = doc._document.data.value.mapValue.fields;
    if (doc.ref._path.segments[6] == userID) {
      data.push({
        id,
        outletsSelected,
        names,
        type,
        status,
        hours,
        minutes,
        tomorrow,
        time,
      });
    }
  });

  return { ...DEFAULT_STATE.rules, data };
}

export function buildMissions(missionsSnapshot, userID) {
  const missions = [];

  missionsSnapshot.docs.forEach((doc) => {
    const {
      name,
      description,
      names,
      outletIds,
      idleHours,
      repeat,
      status,
      teakCoins,
      potentialEnergySavings,
      potentialCO2Savings,
      potentialPriceSavings,
      type,
      createdAt,
    } = doc._document.data.value.mapValue.fields;
    if (doc.ref._path.segments[6] == userID) {
      missions.push({
        id: doc.id,
        name,
        description,
        names,
        outletIds,
        idleHours,
        repeat,
        status,
        teakCoins,
        potentialEnergySavings,
        potentialCO2Savings,
        potentialPriceSavings,
        type,
        createdAt: new Date(createdAt),
      });
    }
  });

  missions.sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));

  return { ...DEFAULT_STATE.missions, data: missions };
}

export function buildGlobal(globalSnapshot) {
  const data = {};
  globalSnapshot.docs.forEach((doc) => {
    data[doc.id] = doc._document.data.value.mapValue.fields;
  });

  return { ...DEFAULT_STATE.global, data };
}

export default function buildAppState({
  userSnapshot,
  outletsSnapshot,
  groupsSnapshot,
  schedulesSnapshot,
  timersSnapshot,
  missionsSnapshot,
  globalSnapshot,
  userID,
  wattHoursYears,
  wattHoursMonths,
  wattHoursWeeks,
  wattHoursDays,
}) {
  return {
    auth: { ...DEFAULT_STATE.auth, isLoggedIn: true },
    user: buildUser(userSnapshot),
    outlets: buildOutlets(outletsSnapshot, userID),
    usage: buildUsageData(outletsSnapshot, userID, wattHoursYears, wattHoursMonths, wattHoursWeeks, wattHoursDays,),
    groups: buildGroups(groupsSnapshot, userID),
    rules: buildRules(schedulesSnapshot, timersSnapshot, userID),
    missions: buildMissions(missionsSnapshot, userID),
    global: buildGlobal(globalSnapshot),
    getUserRef: getUserRef(userID),
    userID
    // testRef: testRef
  };
}
//still trying to fix this, just need to actually get ID sent to this function
export function getUserRef(userID) {
  return doc(db, 'users', userID)
}
// export const testRef = doc(db, 'users', uid);