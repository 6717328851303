import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { green, greyDark, greyLight, yellow } from '../../../../lib/colors';

export default function OutletStatusListContainer({
    outlets,
    height,
    width
}) {
    if (outlets.length > 0) {
        
        //create object to get the wifi connection status information
        let wifi_count = {};
        outlets.forEach(outlet => {
            const { wifiStatus } = outlet;
            if (wifi_count[wifiStatus]) {
                wifi_count[wifiStatus]++;
            } else {
                wifi_count[wifiStatus] = 1;
            }
        });
        const sorted_keys = Object.keys(wifi_count).sort();
        const sorted_count = {}
        sorted_keys.forEach(key => {
            sorted_count[key] = wifi_count[key];
        });

        return (
            <div style={{
                display: 'flex',
                backgroundColor: greyLight,
                borderRadius: '15px',
                width: '99%',
                height: width*0.06,//windowDimensions.width < 1100 ? '6vw' : '95px',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '1%'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-evenly',
                    width:'100%',
                    alignItems: 'center',
                    marginTop: '2%',
                    marginBottom: '2%'
                }}
                >
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: width*0.105,
                    }}
                    >
                        <p style={{
                            fontSize: width < 1200 ? width*0.0175 : '22.5px',
                            textDecorationLine: 'underline',
                            fontFamily: 'manrope',
                            marginBottom: '7.5%'
                        }}>
                            Total
                        </p>
                        <p style={{
                            fontSize: width < 1200 ? width*0.0175 : '22.5px',
                            fontFamily: 'manrope',
                        }}>
                            {outlets?.length} Outlets
                        </p>
                    </div>
                    {Object.keys(sorted_count).map(statusGroup => {
                        let statusStyle = {
                            color: yellow,
                            fontSize: width < 1200 ? width*0.0175 : '22.5px',
                            fontFamily: 'manrope',
                            textDecorationLine: 'underline',
                            marginBottom: '7.5%',
                        };
                        let outletNumberStyle = {
                            fontSize: width < 1200 ? width*0.0175 : '22.5px',
                            fontFamily: 'manrope',
                        };
                        if (statusGroup === 'Connected') {
                            statusStyle.color = green;
                        } else if (statusGroup === 'Disconnected') {
                            statusStyle.color = greyDark;
                        }
                        else if (statusGroup === 'Not activated') {
                            statusStyle.color = 'orange';
                        };
                        return (
                            <li key={statusGroup} style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: width*0.13,
                            }}>
                                <p style={statusStyle}>
                                {statusGroup}
                                </p>
                                <p style={outletNumberStyle}>
                                    {sorted_count[statusGroup]} Outlets
                                </p>
                            </li>
                        );
                    })}
                </div>
            </div>

        );
    } else {
        return (
            <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    height: '6vw',
                    alignItems: 'center'
                }}>
                    Loading...
                </div>
            </>
        );
    };
};