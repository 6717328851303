import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Alert, // Import Alert from Material UI
} from "@mui/material";
import UsageDashboardContainer from "../../pages/home/usageDashboardContainer";
import { fetchWattHours, fetchAverageUsage } from "../../functions/wattHours";
import { green, greyDark, orange } from "../../lib/colors";
import { storage } from "../../firebase";
import { ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage';
import imageCompression from "browser-image-compression";
import { submitNameEdit } from "../../functions/outletList";

export default function OutletModal({
  isOpen,
  handleClose,
  outlet,
  rate,
  dailyUsage,
  submitEditName,
  selectOnPress,
  width,
  height,
  outlets,
  setOutlets,
}) {
  const [name, setName] = useState(outlet.name);
  const [productType, setProductType] = useState(outlet.productType || "");
  const [wattsLoading, setWattsLoading] = useState(true);
  const [productTypes, setProductTypes] = useState([]);
  // const [outlets, setOutlets] = useState(
  //   JSON.parse(localStorage.getItem("outlets"))
  // );
  const [wattHours, setWattHours] = useState([]);
  const [outletAverageUsage, setOutletAverageUsage] = useState([]);
  const [orgID, setOrgID] = useState(localStorage.getItem("orgID"));
  // const [windowDimensions, setWindowDimensions] = useState(
  //   getWindowDimensions()
  // );
  const [dataFetched, setDataFetched] = useState(false);
  const [selectedOrgID, setSelectedOrgID] = useState(
    localStorage.getItem("selectedOrgID")
  );

  const [outletUrl, setOutletUrl] = useState();
  const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility
  const [deleteClick, setDeleteClick] = useState(false);

  useEffect(() => {
    const types = JSON.parse(localStorage.getItem("productTypes"));
    if (types) {
      const uniqueTypes = [...new Set(types)];
      setProductTypes(uniqueTypes);
    }
  }, []);

  useEffect(() => {
    if (isOpen && !dataFetched) {
      if (selectedOrgID) {
        fetchWattHours(selectedOrgID, setWattHours, setWattsLoading, outlet.id);
        fetchAverageUsage(selectedOrgID, setOutletAverageUsage, outlet.id);
      } else {
        fetchWattHours(orgID, setWattHours, setWattsLoading, outlet.id);
        fetchAverageUsage(orgID, setOutletAverageUsage, outlet.id);
      }
      setDataFetched(true);
    }
  }, [isOpen, dataFetched, orgID, selectedOrgID, outlet.id]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSaveClick = async (event) => {
    let success = await submitNameEdit(
      outlet.orgID,
      outlet.id,
      outlet.docID,
      name
    );
    if (!success) {
      setShowAlert(true);
      setName(outlet.name);
    } else {
      setShowAlert(false);
      setOutlets((prevOutlets) =>
        prevOutlets.map((o) => (o.id === outlet.id ? { ...o, name: name } : o))
      );
      handleClose(event);
    }
  };

  const handleApplianceChange = (event) => {
    const productType = event.target.value;
    setProductType(productType);
    selectOnPress(event);
  };

  const fileInputRef = useRef(null);
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
    if (file) {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      });
      const storageRef = ref(storage, fileName);
      try {
        const snapshot = await uploadBytes(storageRef, compressedFile);
        console.log('Upload file', snapshot);
        const downloadUrl = await getDownloadURL(snapshot.ref);
        setOutletUrl(downloadUrl);
        console.log('file at', downloadUrl);
      } catch (error) {
        console.error('error uploading', error);
      };
    };
  };

  const handleDeleteClick = () => {
    setDeleteClick(!deleteClick)
  }
  const handleDelete = async (event) => {
    const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
    const storageRef = ref(storage, fileName);
    // Delete the file
    deleteObject(storageRef).then(() => {
      // File deleted successfully
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
    setOutletUrl();
    setDeleteClick(false)
  }

  useEffect(() => {
    if (isOpen) {
      // console.log('outlet', outlet);
      const fileName = `images/${outlet.orgID}/${outlet.device}/${outlet.device}`;
      const fetchImageUrl = async () => {
        try {
          const fileRef = ref(storage, fileName);
          const url = await getDownloadURL(fileRef);
          setOutletUrl(url);
          // console.log('url', url);
        } catch (error) {
          console.error("Error fetching image URL:", error);
        };
      };
      fetchImageUrl();
    };
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={(event) => {
        handleClose(event);
        setDataFetched(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "70%",
          height: "90%",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {outlet.name} Details
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleSaveClick} variant="contained">
              Save
            </Button>
          </Box>
        </Box>

        <Box style={{alignSelf:'center', marginTop:'10px'}}>
            {outletUrl ?
            <Box style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              <img
                src={outletUrl}
                alt="Photo of Outlet"
                style={{
                  maxWidth: '300px',
                  maxHeight: '300px',
                }}
              /> 
              <Box
              sx={{ display: "flex", justifyContent: "flex-end", gap: 2, marginTop: '10px' }}
              >
                <Button onClick={handleUploadClick} variant="outlined">
                  Change Image
                </Button>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                <Button onClick={handleDeleteClick} variant="contained" style={{backgroundColor:'red'}}>
                  Delete Image
                </Button>
              </Box>
              <Modal open={deleteClick}       
                onClose={(event) => {
                  handleDeleteClick(event);
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "40%",
                    height: "8%",
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                    overflow: "auto",
                    borderRadius: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems:'center'
                  }}
                >
                  <div>Confirm you would like to delete the image</div>
                  <Box
                  sx={{ display: "flex", justifyContent: "flex-end", gap: 2, marginTop: '20px' }}
                  >
                    <Button onClick={handleDeleteClick} variant="outlined">
                    Cancel
                    </Button>
                    <Button onClick={handleDelete} variant="contained" style={{backgroundColor:'red'}}>
                      Delete
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
              
              
              :
              <>
                <button
                  style={{
                    width: '200px',
                    height: '200px',
                    borderWidth: '3px',
                    borderStyle: 'dashed',
                    borderColor: orange,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    fontFamily: 'Manrope',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }}
                  onClick={handleUploadClick}
                >
                  Upload Image
                </button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </>
            }
          </Box>

        {showAlert && (
          <Alert
            severity="warning"
            onClose={() => setShowAlert(false)}
            style={{ marginTop: 16 }}
          >
            The outlet name already exists. Please choose a different name.
          </Alert>
        )}

        <Box sx={{ mt: 2 }}>
          <TextField
            label="Edit Name"
            value={name}
            onChange={handleNameChange}
            fullWidth
            margin="dense"
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="product-type-label">Select Product Type</InputLabel>
            <Select
              labelId="product-type-label"
              value={productType === "" ? "N/A" : productType}
              onChange={handleApplianceChange}
              label="Select Product Type"
              sx={{
                color:
                  productType === "N/A" || productType === ""
                    ? "orange"
                    : "inherit",
              }}
            >
              {productTypes.map((type, index) => (
                <MenuItem
                  key={index}
                  value={type}
                  style={{
                    color: type === "N/A" ? "orange" : "inherit",
                  }}
                >
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box style={{ marginTop: "2%", display: 'flex', justifyContent: 'center' }}>
          <UsageDashboardContainer
            orgID={selectedOrgID ? selectedOrgID : orgID}
            outlets={outlets}
            outletAverageUsage={outletAverageUsage}
            wattHours={wattHours}
            height={height}
            width={width < 1400 ? width * 0.85 : width * 0.735}
            singleOutlet={true}
            outlet={outlet}
            rate={rate}
          />
        </Box>
      </Box>
    </Modal >
  );
}
