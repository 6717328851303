import { collection, doc, getDocs, query, where } from "firebase/firestore";
import db from "../firebase";

export const fetchLifetimeSavings = async (
  orgID,
  setLifeCO2eSavings,
  setLifeCostSavings,
  setLifeEnergySavings,
  rate
) => {
  const getLifetimeSavings = async () => {
    const lifetimeQ = query(
      collection(db, "organizations"),
      where("uid", "==", orgID)
    );

    const lifetimeSnapshot = await getDocs(lifetimeQ);
    lifetimeSnapshot.forEach((doc) => {
      setLifeCO2eSavings(doc.data()["rev_co2eSavings"]);
      setLifeCostSavings((doc.data()["rev_energySavings"]/1000)*rate);
      setLifeEnergySavings(doc.data()["rev_energySavings"]);
    });
  };
  getLifetimeSavings();
};

export const fetchMonthlySavings = async (
  orgID,
  setMonthlyCO2eSavings,
  setMonthlyCostSavings,
  setMonthlyEnergySavings,
  year,
  month,
  rate,
  outletID = null,
) => {
  const getMonthlySavings = async () => {
    const co2eSavingsArray = [];
    const costSavingsArray = [];
    const energySavingsArray = [];
    let monthQ;
    if (outletID) {
      monthQ = query(
        collection(db, "savings"),
        where("orgID", "==", orgID),
        where("date", "==", `${year}-${month}-01 00:00:00`),
        where("outletID", "==", outletID)
      );
    } else {
      monthQ = query(
        collection(db, "savings"),
        where("orgID", "==", orgID),
        where("date", "==", `${year}-${month}-01 00:00:00`)
      );
    }
    const monthSnapshot = await getDocs(monthQ);
    monthSnapshot.forEach((doc) => {
      co2eSavingsArray.push({
        [doc.data().outletID]: doc.data().co2eSavings,
      });
      costSavingsArray.push({
        [doc.data().outletID]: (doc.data().energySavings/1000)*rate,
      });
      energySavingsArray.push({
        [doc.data().outletID]: doc.data().energySavings,
      });
    });
    setMonthlyCO2eSavings(co2eSavingsArray);
    setMonthlyCostSavings(costSavingsArray);
    setMonthlyEnergySavings(energySavingsArray);
  };
  getMonthlySavings();
};
