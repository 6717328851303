import { collection, getDocs, doc, getDoc, where, query } from "firebase/firestore";
import db from "../firebase";

const fetchAllOutletsInfo = async () => {
  try {
    // console.log("Fetching organizations...");
    const outletsRef = collection(db, "outlets");
    const outletSnapshot = await getDocs(outletsRef);
    const outlets = [];
    const organizationsRef = collection(db, "organizations");
    const orgSnapshot = await getDocs(organizationsRef);
    const organizations = [];
    //const orgIDs = []
    //const orgNames = []

    //for (const orgDoc of orgSnapshot.docs) {

    //put all org data into a dictionary to be sorted through once the outlet is known
    for (const orgDoc of orgSnapshot.docs){
      const orgData = orgDoc.data()
      //orgIDs.push(orgDoc.uid())
      //orgNames.push(orgDoc.name)
      organizations.push({
        orgID: orgData.uid,
        orgName: orgData.name,
        timezone: orgData.timezone
      })
    }

    //go through all outlets and retrieve their data
    for (const outletDoc of outletSnapshot.docs) {
      const outletData = outletDoc.data();

      const name = outletData.name;
      const lastOffUpdate = outletData.lastOffUpdate;
      const lastOnUpdate = outletData.lastOnUpdate;
      const lastDataUpdate = outletData.lastDataUpdate;
      const orgID = outletData.orgID;
      let orgName = "No data";

      //using the org information pulled earlier find the org name and timezone 
      //associated with the specific outlet
      const orgIndex = organizations.findIndex(obj => obj.orgID === orgID);
      orgName = organizations[orgIndex].orgName
      const timezone = organizations[orgIndex].timezone || "UTC"; // timezone
      
      /*const q = query(orgRef, where("uid", "==", orgID));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        if(doc.data().name !== undefined){
          orgName = doc.data().name
        }
      });*/


      // Get the current date and time as a Date object
      const now = new Date();
      const nowTZ = new Date(now.toLocaleString('en-US', { timeZone: timezone }));

      // Subtract 5 minutes from the current time
      // Multiply minutes by 60000 to convert minutes to milliseconds
      const fiveMinutesAgo = new Date(nowTZ.getTime() - 5 * 60000);
      let wifiStatus = "Not activated";

      if (!lastDataUpdate || lastDataUpdate === "No data") {
        wifiStatus = "Not activated";
      } else {
        // Parse the lastDataUpdate string into a Date object
        const lastUpdateDate = new Date(lastDataUpdate);
        lastUpdateDate.toLocaleString('en-US', { timeZone: timezone })

        // Compare lastDataUpdate with five minutes ago
        if (lastUpdateDate >= fiveMinutesAgo) {
          wifiStatus = "Connected";
        } else {
          wifiStatus = "Disconnected";
        }
      }

      /*const docRef = doc(outletDoc.ref, "Status", "status");
      const docSnap = await getDoc(docRef);*/

      let status = "On"
      
      if(lastOnUpdate > lastOffUpdate){
        status = "On"
      }
      else if(!lastOnUpdate && !lastOffUpdate){
        status = "On"
      }
      else{
        status = "Off"
      }

      /*if (docSnap.exists()) {
        status = docSnap.data().status 
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }*/

      const oneWeekAgo = new Date(nowTZ.getDate() - 8);
      let scheduleStatus = "Not active";

      if (!lastOnUpdate) {
        scheduleStatus = "Not active";
      } else {
        // Parse the lastDataUpdate string into a Date object
        const lastOnUpdateDate = new Date(lastOnUpdate);
        lastOnUpdateDate.toLocaleString('en-US', { timeZone: timezone })

        // Compare lastDataUpdate with five minutes ago
        if (lastOnUpdateDate >= oneWeekAgo) {
          scheduleStatus = "Active";
        }
      }

      outlets.push({
        ...outletData,
        outletId: outletDoc.id,
        orgName,
        name,
        lastOffUpdate,
        lastOnUpdate,
        lastDataUpdate,
        wifiStatus,
        status,
        scheduleStatus,
      });
    }
    
      /*const orgData = orgDoc.data();
      const timezoneOffset = orgData.timezoneOffset || 0; // Offset in hours

      const outletsRef = collection(db, `organizations/${orgDoc.id}/Outlets`);
      const outletsSnapshot = await getDocs(outletsRef);

      const outlets = [];

      const formatToUTC = (dateString, offset) => {
        if (!dateString) return "No data";
        const localDate = new Date(dateString);
        const offsetMilliseconds = 3600000 * offset;
        const adjustedDate = new Date(localDate.getTime() - offsetMilliseconds);
        return adjustedDate.toLocaleString();
      };

      for (const outletDoc of outletsSnapshot.docs) {
        const outletData = outletDoc.data();

        const lastOffUpdate = formatToUTC(
          outletData.lastOffUpdate,
          timezoneOffset
        );
        const lastOnUpdate = formatToUTC(
          outletData.lastOnUpdate,
          timezoneOffset
        );
        const cloudTrackingRef = doc(
          db,
          `organizations/${orgDoc.id}/Outlets/${outletDoc.id}/cloudTracking/lastDataUpdate`
        );
        const cloudTrackingDoc = await getDoc(cloudTrackingRef);

        let lastDataUpdate = "No data";
        if (cloudTrackingDoc.exists()) {
          const lastDataUpdateRaw = cloudTrackingDoc.data().lastDataUpdate;
          lastDataUpdate = formatToUTC(lastDataUpdateRaw, timezoneOffset);
        }

        // Get the current date and time as a Date object
        const now = new Date();

        // Subtract 5 minutes from the current time
        // Multiply minutes by 60000 to convert minutes to milliseconds
        const fiveMinutesAgoUTC = new Date(now.getTime() - 5 * 60000);
        let wifiStatus;
        if (!lastDataUpdate || lastDataUpdate === "No data") {
          wifiStatus = "Not activated";
        } else {
          // Parse the lastDataUpdate string into a Date object
          const lastUpdateDate = new Date(lastDataUpdate);

          // Compare lastDataUpdate with five minutes ago
          if (lastUpdateDate >= fiveMinutesAgoUTC) {
            wifiStatus = "Connected";
          } else {
            wifiStatus = "Disconnected";
          }
        }
        outlets.push({
          ...outletData,
          outletId: outletDoc.id,
          lastOffUpdate,
          lastOnUpdate,
          lastDataUpdate,
          wifiStatus,
        });
      }

      organizations.push({
        ...orgData,
        organizationId: orgDoc.id,
        outlets,
      });
    }*/

    return outlets;
  } catch (error) {
    console.error("Error fetching data: ", error);
    return [];
  }
};

export { fetchAllOutletsInfo };
