import React, { useEffect, useState } from "react";
import { View } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { Typography } from "@mui/material";
import Header from "../../components/header";
import DataGridTable from "../../components/data-grid";
import { fetchAllOutletsInfo } from "../../functions/allOutletsInfo";
import { useLocation } from 'react-router-dom';
import { green, greyDark, greyLight, yellow } from '../../lib/colors';
import OrganizationsList from "../../components/organizationsList";

export default function OrganizationsScreen() {
  const [user, waiting, error] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [outlets, setOutlets] = useState([]);
  const navigate = useNavigate();
  const orgID = localStorage.getItem("orgID");
  useEffect(() => {
    if (waiting) {
      return;
    }
    // console.log(user.email);
    if (!user) navigate("/login");
  }, [user, waiting]);

  useEffect(() => {
    const getOrganizations = async () => {
      setLoading(true);
      const outletsData = await fetchAllOutletsInfo();
      //console.log("Fetched outlets data: ", outletsData);

      // get the unique orgIDs from the outletsData array of objects
      let orgNames = outletsData.map(obj => obj.orgName);
      // Step 2: Use a Set to get unique values
      let uniqueOrgs = new Set(orgNames);
      // Step 3: Convert Set back to an array
      uniqueOrgs = [...uniqueOrgs].sort();
      const totalOrgData = []
      for (let org in uniqueOrgs) {
        let orgHelperData = {};
        let orgOutlets = outletsData.filter(obj => obj.orgName === uniqueOrgs[org])
        orgHelperData['name'] = uniqueOrgs[org]
        orgHelperData['total'] = orgOutlets.length
        let connected = 0
        let disconnected = 0
        let notActivated = 0
        let activeSchedule = 0
        for (let outlet in orgOutlets) {
          let wifiStatus = orgOutlets[outlet].wifiStatus
          if (wifiStatus == 'Connected') {
            connected += 1
          } else if (wifiStatus == 'Disconnected') {
            disconnected += 1
          } else {
            notActivated += 1
          }
          let scheduleStatus = orgOutlets[outlet].scheduleStatus
          if (scheduleStatus == 'Active') {
            activeSchedule += 1
          }
        }
        orgHelperData['connected'] = connected
        orgHelperData['disconnected'] = disconnected
        orgHelperData['notActivated'] = notActivated
        orgHelperData['active'] = activeSchedule
        // console.log(orgHelperData)
        totalOrgData.push(orgHelperData)
      }

      setOutlets(outletsData);
    //   setOrgData(totalOrgData);
      setLoading(false);
    };

    getOrganizations();
  }, []);

  return (
    <>
      {orgID === "LZqI3R6MInUuwtgtROPK" && (
        <View
          style={{
            marginTop: 75,
          }}
        >
          <Header orgID={orgID} />
          <OrganizationsList></OrganizationsList>
        </View>
      )}
    </>
  );
}
