import React from "react";

export default function Modal({ isOpen, onClose, children }) {
    if (!isOpen) {
        return null;
    };
    return (
        <div
            onClick={onClose}
            style={{
                position: 'fixed',
                top: '0%',
                left: '0%',
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.85)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div>
                {children}
            </div>
        </div>
    );
};