import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, CircularProgress } from "@mui/material";
import { fetchUsersFromOrganization } from "../../functions/users";

export default function OrganizationModal({
  open,
  handleClose,
  org,
  organizations,
}) {
  const [organization, setOrganization] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!org) return;

    const organization = organizations.find(
      (organization) => organization.name.stringValue === org.name
    );

    if (organization) {
      setOrganization(organization);

      const fetchUsers = async () => {
        setLoading(true);
        try {
          const fetchedUsers = await fetchUsersFromOrganization(
            organization.uid.stringValue
          );
          setUsers(fetchedUsers);
        } catch (error) {
          console.error("Error fetching users:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchUsers();
    }
  }, [org]);

  if (!organization) return null;

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ width: "60%" }}>
          <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
            {organization.name.stringValue}
          </Typography>
          {organization.uid && organization.uid.stringValue && (
            <Typography sx={{ mt: 1 }}>
              Organization ID: {organization.uid.stringValue}
            </Typography>
          )}
          {organization.joinDate && organization.joinDate.stringValue && (
            <Typography sx={{ mt: 1 }}>
              Join Date: {organization.joinDate.stringValue}
            </Typography>
          )}
          {organization.rate && organization.rate.doubleValue && (
            <Typography sx={{ mt: 1 }}>
              Rate: {organization.rate.doubleValue}
            </Typography>
          )}

          {organization.region && organization.region.stringValue && (
            <Typography sx={{ mt: 1 }}>
              Region: {organization.region.stringValue}
            </Typography>
          )}
          {organization.timezone && organization.timezone.stringValue && (
            <Typography sx={{ mt: 1 }}>
              Timezone: {organization.timezone.stringValue}
            </Typography>
          )}
          {organization.zipcode && organization.zipcode.stringValue && (
            <Typography sx={{ mt: 1 }}>
              Zipcode: {organization.zipcode.stringValue}
            </Typography>
          )}
        </Box>
        <Box sx={{ width: "40%", maxHeight: "300px", overflowY: "auto" }}>
          <Typography variant="h6" component="h2" sx={{ fontWeight: "bold" }}>
            Users
          </Typography>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <CircularProgress />
            </Box>
          ) : users.length > 0 ? (
            users.map((user, index) => (
              <Typography key={index} sx={{ mt: 1 }}>
                {user.email}
              </Typography>
            ))
          ) : (
            <Typography sx={{ mt: 1 }}>No users found.</Typography>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
