import React, { useState } from "react";
import { Button, TextField, Alert, Typography, Box, IconButton, InputAdornment } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { registerNewUser, addUserToOrganization } from "../../functions/users";
import { fetchOrganizationFromID } from "../../functions/organization";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import {blue, green} from "../../lib/colors";

const RegisterScreen = () => {
  const [orgId, setOrgId] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [orgFound, setOrgFound] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "",
  });
  const navigate = useNavigate()

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailValid(isValidEmail(newEmail));
  };

  const isValidPassword = (password) => {
    return password.length >= 8 &&
           /[a-z]/.test(password) &&
           /[A-Z]/.test(password) &&
           /\d/.test(password);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    if (!isValidPassword(newPassword)) {
      setAlert({
        show: true,
        message: "Password must be at least 8 characters long and include a number, a lowercase and an uppercase letter.",
        severity: "error",
      });
    } else {
      setAlert({ show: false, message: "", severity: "" });
    }
  };

  const handleOrgSearch = async () => {
    try {
      const orgDoc = await fetchOrganizationFromID(orgId);
      if (!orgDoc) {
        setAlert({
          show: true,
          message: "Organization not found.",
          severity: "error",
        });
        setOrgFound(false);
      } else {
        setAlert({
          show: true,
          message: `Organization found: ${orgDoc.name}`,
          severity: "success",
        });
        setOrgFound(true);
      }
    } catch (error) {
      setAlert({
        show: true,
        message: "Error searching organization.",
        severity: "error",
      });
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!orgFound || !emailValid || !isValidPassword(password)) {
      setAlert({
        show: true,
        message: "Please ensure all fields are correctly filled out.",
        severity: "error",
      });
      return;
    }

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        await registerNewUser(user,orgId)
        await addUserToOrganization(user.uid, orgId)
        setAlert({
          show: true,
          message: "Registration successful!",
          severity: "success",
        });
        setTimeout(() => {
          navigate('/');  
        }, 3000);
      })
      .catch((error) => {
        let errorMessage = "Error in registration.";
        if (error.code === 'auth/email-already-in-use') {
          errorMessage = "The email address is already in use by another account.";
        } else if (error.code === 'auth/weak-password') {
          errorMessage = "The password is too weak.";
        }
        setAlert({
          show: true,
          message: errorMessage,
          severity: "error",
        });
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: "60%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "500px",
        }}
      >
        <img
          src={logo}
          alt="ReVert Logo"
          style={{
            width: "400px",
            height: "auto",
            display: "block",
            marginTop: "2.5%",
            marginBottom: "10%",
          }}
        />
        <Typography variant="h4" sx={{ fontFamily: "Manrope", fontWeight: "250" }}>
          Sign Up
        </Typography>
        <TextField
          label="Organization ID"
          value={orgId}
          onChange={(e) => setOrgId(e.target.value)}
          fullWidth
          margin="normal"
          sx={{ fontFamily: "Manrope", fontWeight: "250" }}
          InputLabelProps={{
            style: {
              fontFamily: "Manrope",
              fontWeight: 250,
            },
          }}
          disabled={orgFound}
        />
        <Button
          variant="contained"
          onClick={handleOrgSearch}
          fullWidth
          disabled={orgFound}
          style={{backgroundColor: blue}}
        >
          Search Organization
        </Button>

        {alert.show && (
          <Alert severity={alert.severity} sx={{ mt: 2 }}>
            {alert.message}
          </Alert>
        )}

        {orgFound && (
          <>
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={handleEmailChange}
              fullWidth
              margin="normal"
              required
              sx={{
                fontFamily: "Manrope",
                fontWeight: "250",
                color: "greyUltraDark",
              }}
              InputLabelProps={{
                style: {
                  fontFamily: "Manrope",
                  fontWeight: 250,
                  color: "#A9A9A9",
                },
              }}
              error={!emailValid && email.length > 0}
              helperText={!emailValid && email.length > 0 ? "Please enter a valid email address" : ""}
            />
            <TextField
              label="Password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              fullWidth
              margin="normal"
              required
              sx={{ fontFamily: "Manrope", fontWeight: "250" }}
              InputLabelProps={{
                style: {
                  fontFamily: "Manrope",
                  fontWeight: 250,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              disabled={!orgFound || !emailValid || !isValidPassword(password)}
              style={{backgroundColor: green}}
            >
              Register
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default RegisterScreen;