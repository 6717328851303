import React, { useEffect, useState } from "react";
import { View } from "react-native-web";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import Header from "../../components/header";
import UsersDataGridTable from "../../components/users-data-grid";

import { fetchUsersFromOrganization } from "../../functions/users";
import {useLocation} from 'react-router-dom';

export default function UsersScreen() {
  const [user, waiting, error] = useAuthState(auth);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([])
  const navigate = useNavigate();
  const location = useLocation();
  const orgID = localStorage.getItem("orgID");
  useEffect(() => {
    if (waiting) {
      return;
    }
    // console.log(user.email);
    if (!user) navigate("/login");
  }, [user, waiting]);

  
  const getUsers = async () => {
    setLoading(true);
    const usersData = await fetchUsersFromOrganization(orgID);
    setUsers(usersData);
    setLoading(false);
  };

  useEffect(() => {
    if(orgID){
      getUsers();
    }
  }, []);
  return (
    <>
        <View
          style={{
            marginTop: "6.5%",
          }}
        >
          <Header orgID={orgID}/>
          <UsersDataGridTable
            data={users}
            loading={loading}
            getUsers ={getUsers}
            orgId = {location.state}
          ></UsersDataGridTable>
        </View>
      
    </>
  );
}
