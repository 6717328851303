export const HYDRATE = 'HYDRATE';
export const LOGOUT = 'LOGOUT';

// auth
export const LOGIN_INIT = 'LOGIN_INIT';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_INIT = 'LOGOUT_INIT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const CLEAR_LOGOUT_ERROR = 'CLEAR_LOGOUT_ERROR';
export const DELETE_INIT = 'DELETE_INIT';
export const DELETE_ERROR = 'DELETE_ERROR';
export const DELETE_SUCCESS = 'DELETE_SUCCESS';

// outlets
export const OUTLETS_INIT = 'OUTLETS_INIT';
export const ADD_OUTLETS_SUCCESS = 'ADD_OUTLETS_SUCCESS';
export const EDIT_OUTLET_SUCCESS = 'EDIT_OUTLET_SUCCESS';
export const SET_OUTLET_STATUS_SUCCESS = 'SET_OUTLET_STATUS_SUCCESS';
export const SET_OUTLETS_SUCCESS = 'SET_OUTLETS_SUCCESS';
export const UPDATE_OUTLETS = 'UPDATE_OUTLETS';
export const OUTLETS_ERROR = 'OUTLETS_ERROR';

// groups
export const GROUP_INIT = 'GROUP_INIT';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const TURN_GROUP_ON_SUCCESS = 'TURN_GROUP_ON_SUCCESS';
export const TURN_GROUP_OFF_SUCCESS = 'TURN_GROUP_OFF_SUCCESS';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';
export const GROUP_ERROR = 'GROUP_ERROR';

// usage data
export const USAGE_DATA_INIT = 'USAGE_DATA_INIT';
export const USAGE_DATA_SUCCESS = 'USAGE_DATA_SUCCESS';
export const DAILY_USAGE_DATA_SUCCESS = 'DAILY_USAGE_DATA_SUCCESS';
export const WEEKLY_USAGE_DATA_SUCCESS = 'WEEKLY_USAGE_DATA_SUCCESS';
export const MONTHLY_USAGE_DATA_SUCCESS = 'MONTHLY_USAGE_DATA_SUCCESS';
export const YEARLY_USAGE_DATA_SUCCESS = 'YEARLY_USAGE_DATA_SUCCESS';
export const USAGE_DATA_ERROR = 'USAGE_DATA_ERROR';
export const BUILD_USAGE_DATA = 'BUILD_USAGE_DATA';

// idle load
export const IDLE_LOAD_DATA_INIT = 'IDLE_LOAD_DATA_INIT';
export const IDLE_LOAD_DATA_SUCCESS = 'IDLE_LOAD_DATA_SUCCESS';
export const WEEKLY_IDLE_LOAD_DATA_SUCCESS = 'WEEKLY_IDLE_LOAD_DATA_SUCCESS';
export const MONTHLY_IDLE_LOAD_DATA_SUCCESS = 'MONTHLY_IDLE_LOAD_DATA_SUCCESS';
export const YEARLY_IDLE_LOAD_DATA_SUCCESS = 'YEARLY_IDLE_LOAD_DATA_SUCCESS';
export const IDLE_LOAD_DATA_ERROR = 'IDLE_LOAD_DATA_ERROR';
export const BUILD_IDLE_LOAD_DATA = 'BUILD_IDLE_LOAD_DATA';

// eco stats
export const ECO_STATS_INIT = 'ECO_STATS_INIT';
export const ECO_STATS_ERROR = 'ECO_STATS_ERROR';
export const WEEKLY_ECO_STATS_SUCCESS = 'WEEKLY_ECO_STATS_SUCCESS';
export const EMISSIONS_INIT = 'EMISSIONS_INIT';
export const EMISSIONS_ERROR = 'EMISSIONS_ERROR';
export const EMISSIONS_SUCCESS = 'EMISSIONS_SUCCESS';

// missions
export const UPDATE_MISSIONS = 'UPDATE_MISSIONS';
export const MISSIONS_INIT = 'MISSIONS_INIT';
export const MISSIONS_ERROR = 'MISSIONS_ERROR';
export const MISSION_UPDATE_SUCCESS = 'MISSION_UPDATE_SUCCESS';

// rules
export const RULE_INIT = 'RULE_INIT';
export const RULE_ERROR = 'RULE_ERROR';
export const ADD_RULE_SUCCESS = 'ADD_RULE_SUCCESS';
export const EDIT_RULE_SUCCESS = 'EDIT_RULE_SUCCESS';
export const DELETE_RULE_SUCCESS = 'DELETE_RULE_SUCCESS';

// user
export const USER_INIT = 'USER_INIT';
export const SET_USER = 'SET_USER';
export const USER_ERROR = 'USER_ERROR';

// global
export const SET_GLOBAL_DATA = 'SET_GLOBAL_DATA';
