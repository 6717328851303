import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  InputAdornment, // Import InputAdornment for the $ symbol
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import logo from "../../assets/images/logo.png";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { registerNewUser } from "../../functions/users";
import { registerOrganization } from "../../functions/organization";
import { useNavigate } from "react-router-dom";

export default function OrganizationRegistration() {
  const [organizationName, setOrganizationName] = useState("");
  const [utilityRate, setUtilityRate] = useState("");
  const [rateError, setRateError] = useState(false); 
  const [zipcodeError, setZipcodeError] = useState(false); 

  const [zipCode, setZipCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [emails, setEmails] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alert, setAlert] = useState({
    show: false,
    message: "",
    severity: "",
  });
  const navigate = useNavigate();

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setNewEmail("");
    setErrorMessage("");
  };

  const handleConfirmAddEmail = async () => {
    try {
      const auth = getAuth();
      const signInMethods = await fetchSignInMethodsForEmail(auth, newEmail);

      if (signInMethods.length <= 0) {
        setEmails([...emails, newEmail]);
        handleCloseDialog();
      } else {
        setErrorMessage("This email is already registered for another user.");
      }
    } catch (error) {
      setErrorMessage("Invalid email");
    }
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
  };

  const handleRateChange = (e) => {
    const value = e.target.value;
    // Validate that the input is a valid number (double)
    if (/^\d*\.?\d*$/.test(value)) {
      setUtilityRate(value);
      setRateError(false);
    } else {
      setRateError(true);
    }
  };
  
  const handleZipcodeChange = (e) => {
    const value = e.target.value;
    // Validate that the input is a valid 5-digit ZIP code
    if (/^\d{0,5}$/.test(value)) {
      setZipCode(value);
      setZipcodeError(false);
    } else {
      setZipcodeError(true);
    }
  };

  const handleUsersCreation = async () => {
    const auth = getAuth();
    let users = [];
    for (let i = 0; i < emails.length; i++) {
      const email = emails[i];
      let orgName = organizationName.split(" ");
      const password = `${orgName[0]}${i}!`; // Generate password based on organization name and index

      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        await registerNewUser(user, null, "admin");
        users.push(user.uid);
      } catch (error) {
        console.error(`Error creating user with email ${email}:`, error);
        setAlert({
          show: true,
          message: `Error creating user with email ${email}: ${error.message}`,
          severity: "error",
        });
      }
    }

    return users;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate before submission
    if (rateError) {
      setAlert({
        show: true,
        message: "Please enter a valid utility rate.",
        severity: "error",
      });
      return;
    }
    if (zipcodeError) {
      setAlert({
        show: true,
        message: "Please enter a valid zip code.",
        severity: "error",
      });
      return;
    }

    let users = await handleUsersCreation();

    // Combine the address fields into one address string
    const address = `${streetAddress}, ${city}, ${state} ${zipCode}`;

    const organization = {
      joinDate: "",
      name: organizationName,
      rate: parseFloat(utilityRate), // Ensure only the double value is saved
      uid: "",
      users: users,
      address: address,
      zipcode: zipCode
    };

    await registerOrganization(organization);

    // Show success alert
    setAlert({
      show: true,
      message: "Organization registered successfully!",
      severity: "success",
    });

    // Redirect after 2 seconds
    setTimeout(() => {
      navigate('/organizations');
    }, 2000);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          maxWidth: "500px",
          width: "100%",
          padding: "20px",
          border: "1px solid #e0e0e0",
          borderRadius: "8px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 3,
          }}
        >
          <img
            src={logo}
            alt="ReVert Logo"
            style={{
              width: "100%",
              maxWidth: "400px",
              height: "auto",
            }}
          />
        </Box>
        <Typography
          variant="h4"
          component="h1"
          sx={{ textAlign: "center", mb: 3 }}
        >
          Register Organization
        </Typography>
        {alert.show && (
          <Alert severity={alert.severity} sx={{ mb: 3 }}>
            {alert.message}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            label="Organization Name"
            variant="outlined"
            fullWidth
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Utility Rate"
            variant="outlined"
            fullWidth
            value={utilityRate}
            onChange={handleRateChange}
            error={rateError}
            helperText={rateError ? "Please enter a valid number" : ""}
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
          <TextField
            label="Street Address"
            variant="outlined"
            fullWidth
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="City"
            variant="outlined"
            fullWidth
            value={city}
            onChange={(e) => setCity(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="State"
            variant="outlined"
            fullWidth
            value={state}
            onChange={(e) => setState(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Zip Code"
            variant="outlined"
            fullWidth
            value={zipCode}
            onChange={handleZipcodeChange}
            sx={{ mb: 2 }}
            inputProps={{
              maxLength: 5,
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            error={zipcodeError}
            helperText={zipcodeError ? "Please enter a valid number" : ""}
          />
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            User Emails
          </Typography>
          {emails.map((email, index) => (
            <Box
              key={index}
              sx={{ display: "flex", alignItems: "center", mb: 2 }}
            >
              <TextField
                label={`Email ${index + 1}`}
                variant="outlined"
                fullWidth
                value={email}
                disabled
                sx={{ mr: 2 }}
              />
              <IconButton onClick={() => handleRemoveEmail(index)}>
                <RemoveIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={handleOpenDialog}
            sx={{ mb: 3 }}
          >
            Add Email
          </Button>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Register Organization
          </Button>
        </form>
        <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          aria-labelledby="confirm-email-dialog"
          aria-describedby="confirm-email-description"
        >
          <DialogTitle id="confirm-email-dialog">Confirm Email</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-email-description">
              Please confirm the email to add:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Email"
              type="email"
              fullWidth
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              error={!!errorMessage}
              helperText={errorMessage}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            <Button
              onClick={handleConfirmAddEmail}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
