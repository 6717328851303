import React from "react";
import { green } from "../../../../../lib/colors";

export default function TopEnergyDrainerPresentational({
    dailyTotal,
    topUsageObject,
    outlets,
    height,
    width,
}) {
    const listStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        //marginTop: '3%',
    };
    const outletID = Object.keys(topUsageObject);
    let outletName = "N/A";
    outlets.forEach(outlet => {
        if (outlet.id === outletID[0]) {
            outletName = outlet.name;
        }
    });
    if(outletName !== "N/A"){
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '50%',
                }}>
                    <p style={{
                        fontSize: width < 1450 ? width*0.0125 : '20px',
                        textAlign: 'center',
                        fontFamily: 'manrope',
                    }}>
                        Total daily usage:
                    </p>
                    <p style={{
                            fontSize: width < 1450 ? width*0.015 : '22.5px',
                            textAlign: 'center',
                            fontFamily: 'manrope',
                            minWidth: 'fit-content'
                    }}>
                        {(dailyTotal/1000).toFixed(2)} kWh
                    </p>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '50%',
                }}>
                    <p style={{
                        fontSize: width < 1450 ? width*0.0125 : '20px',
                        textAlign: 'center',
                        fontFamily: 'manrope',
                    }}>
                        Top energy user:
                    </p>
                    <p style={{
                            fontSize: width < 1450 ? width*0.015 : '22.5px',
                            textAlign: 'center',
                            fontFamily: 'manrope',
                            minWidth: 'fit-content'
                    }}>
                        {outletName}
                    </p>
                </div>
            </div>
        );
    } else {
        return (
            <>
                <div>
                    Loading...
                </div>
            </>
        );
    }
};