import React, { useState, useEffect } from "react";
import Icon from "react-crud-icons";
import "../../../node_modules/react-crud-icons/dist/css/react-crud-icons.css";
import {
  fetchIsOverviewAccount,
  orgSelectPress,
} from "../../functions/organization";
import {
  fetchOrganizationIDs,
  fetchSpecificTeamOrganizationIDs,
} from "../../functions/organization";

//must detect in home that current user is memeber of ReVert Org
export default function OrganizationPicker({
  orgID,
  setSelectedOrgID,
  setTimezone,
  setRate,
  setSelectedApplianceType = null,
}) {
  //useEffect to get all of the possible organizations
  //useState variable to hold the list of orgs
  //drop down box with each org as an option
  //sets state of OrgID to be sent into outletList with selected option
  const [orgEdit, setOrgEdit] = useState(false);
  //const [orgName, setOrgName] = useState("ReVert Technologies, Inc.");
  //should be org name not orgID
  const [pickedOrg, setPickedOrg] = useState(
    localStorage.getItem("selectedOrgID") || ""
  );
  const [allOrgs, setAllOrgs] = useState([]);

  useEffect(() => {
    if (orgID === "LZqI3R6MInUuwtgtROPK") {
      fetchOrganizationIDs(setAllOrgs);
    } else if (
        // I need to access an overview account to test if this is working
      fetchIsOverviewAccount(orgID)
    ) {
      // console.log(orgID)
      fetchSpecificTeamOrganizationIDs(orgID, setAllOrgs);
    }
  }, [orgID]);

  const onSelectOrgPress = () => {
    setOrgEdit(true);
  };
  const onCloseOrgPress = () => {
    setOrgEdit(false);
  };
  const selectOrgPress = (event) => {
    //note: function that updates selected orgID
    orgSelectPress(
      pickedOrg,
      event,
      setPickedOrg,
      setOrgEdit,
      setSelectedOrgID,
      setTimezone,
      setRate
    );
    if (setSelectedApplianceType) setSelectedApplianceType("");
  };

  /*get all of the orgs that are in overview accounts to later put in groups*/
  const childrenOrgs = [];
  allOrgs
    .sort((a, b) => {
      if (a.name.stringValue < b.name.stringValue) {
        return -1;
      }
      if (a.name.stringValue > b.name.stringValue) {
        return 1;
      }
    })
    .map((parent_org) => {
      if (parent_org.child_orgs) {
        parent_org.child_orgs.arrayValue.values.map((org) => {
          let child_org = allOrgs.filter(
            (org1) => org1.uid.stringValue === org.stringValue
          );
          childrenOrgs.push(child_org[0]);
        });
      }
    });

  return (
    <div
      style={{
        display: "flex",
        //paddingTop: '.75%'
      }}
    >
      <div
        style={{
          width: "auto",
        }}
      >
        {/* org dropdown */}
        <select
          value={pickedOrg}
          onChange={selectOrgPress}
          style={{
            //marginLeft: '25px',
            width: "75%",
            //padding: '5px',
            fontSize: "15px",
            borderWidth: "0px",
          }}
        >
          {/*assign overview accounts as groups*/}
          {allOrgs.map((parent_org) => {
            if (parent_org.child_orgs) {
              let child_orgs = [];
              parent_org.child_orgs.arrayValue.values.map((org) => {
                let child_org = allOrgs.filter(
                  (org1) => org1.uid.stringValue === org.stringValue
                );
                child_orgs.push(child_org[0]);
              });
              return (
                <optgroup
                  label={parent_org.name.stringValue}
                  key={parent_org.name.stringValue}
                >
                  {child_orgs
                    .sort((a, b) => {
                      if (a.name.stringValue < b.name.stringValue) {
                        return -1;
                      }
                      if (a.name.stringValue > b.name.stringValue) {
                        return 1;
                      }
                    })
                    .map((child) => {
                      return (
                        <option
                          key={child.uid.stringValue}
                          value={child.uid.stringValue}
                        >
                          {child.name.stringValue}
                        </option>
                      );
                    })}
                </optgroup>
              );
            } else if (!childrenOrgs.includes(parent_org)) {
              return (
                <option
                  key={parent_org.uid.stringValue}
                  value={parent_org.uid.stringValue}
                >
                  {parent_org.name.stringValue}
                </option>
              );
            }
          })}
        </select>
      </div>
      <div
        style={
          {
            //marginTop: "1%",
          }
        }
      >
        Select an Organization to View Outlets
      </div>
    </div>
  );
}
