import {
    setDoc,
    arrayUnion,
    arrayRemove,
    collection,
    doc,
    getDoc,
    query,
    where,
    getDocs,
    updateDoc,
    deleteDoc
  } from "firebase/firestore";
  import db, { auth } from "../firebase";
  
  export const registerNewUser = async (user, orgID=null, role=null) => {
      const userRef = doc(db, "users", user.uid);
      await setDoc(userRef, {
        email: user.email, 
        uid: user.uid,
        onBoard: ["addDevice","usageData","addSchedule", "toggle"],
        role: role || "pending",
        orgID: orgID || ""
      });
  };
  
  export const addUserToOrganization = async (uid, orgId) =>{
      try {
          const orgRef = doc(db, "organizations", orgId);
          await updateDoc(orgRef, {
              users: arrayUnion(uid)
          });
          console.log("User added to organization successfully.");
      } catch (error) {
          console.error("Error adding user to organization: ", error);
          throw new Error("Failed to add user to organization.");
      }
  }
  export const removeUserFromOrganization = async (uid, orgId) =>{
      try {
          const orgRef = doc(db, "organizations", orgId);
          await updateDoc(orgRef, {
              users: arrayRemove(uid)
          });
          console.log("User added to organization successfully.");
      } catch (error) {
          console.error("Error adding user to organization: ", error);
          throw new Error("Failed to add user to organization.");
      }
  }

  export const fetchUsersFromOrganization = async (orgID) => {
    try {
      // Fetch the organization document by its ID
      const organizationDocRef = doc(db, "organizations", orgID);
      const organizationDoc = await getDoc(organizationDocRef);
  
      if (organizationDoc.exists()) {
        const organizationData = organizationDoc.data();
        const userUIDs = organizationData.users;
  
        // Proceed to fetch users
        const users = await fetchUsersByUIDs(userUIDs);
        // console.log(users); 
        return users;
      } else {
        console.log("No such organization!");
        return [];
      }
    } catch (error) {
      console.error("Error getting organization:", error);
      return [];
    }
  };
  
  // Function to fetch users by their UIDs
  const fetchUsersByUIDs = async (userUIDs) => {
    try {
      const usersRef = collection(db, "users");
      const queries = [];
  
      for (let i = 0; i < userUIDs.length; i += 10) {
        const subset = userUIDs.slice(i, i + 10);
        const q = query(usersRef, where("uid", "in", subset));
        queries.push(getDocs(q));
      }
  
      const querySnapshots = await Promise.all(queries);
      const users = [];
  
      querySnapshots.forEach((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
      });
  
      return users;
    } catch (error) {
      console.error("Error getting users:", error);
      return [];
    }
  };

  export const fetchUserRole = async (userId) => {
    try {
      // Reference to the user document
      const userDocRef = doc(db, "users", userId);
      
      // Fetch the user document
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        // Extract the "role" attribute
        const userData = userDoc.data();
        const userRole = userData.role;
        
        return userRole;
      } else {
        console.log("No such user!");
        return null;
      }
    } catch (error) {
      console.error("Error getting user role:", error);
      return null;
    }
  };

  export const updateUserRole = async (userId, newRole) => {
    try {
      const userDocRef = doc(db, "users", userId);
      await updateDoc(userDocRef, {
        role: newRole
      });
      console.log(`User role updated to ${newRole} for user ${userId}`);
    } catch (error) {
      console.error("Error updating user role:", error);
    }
  };

  export const deleteFullUser = async (id) => {
    await deleteUser(id);  // First, delete the Firestore document
    // await deleteAuthUser(id);  // Then, delete the Authentication record ----> This does not work, needs to be server-side
  };

  const deleteUser = async (id) => {
    try {
      const docRef = doc(db, "users", id);
      await deleteDoc(docRef);
      console.log("Document deleted with ID: ", id);
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };
  
  const deleteAuthUser = async (id) => {
    try {
      await auth.deleteUser(id);
      console.log("Authentication record deleted for user ID: ", id);
    } catch (error) {
      console.error("Error deleting authentication record: ", error);
    }
  };
  
  // This is a script to update the role of every user that does not have a role assigned. Won't delete just in case.
  export const updateAllUserRoles = async () => {
    try {
      const usersCollectionRef = collection(db, "users");
      const querySnapshot = await getDocs(usersCollectionRef);
      
      querySnapshot.forEach(async (userDoc) => {
        const userData = userDoc.data();
        if (!userData.hasOwnProperty('role')) {
          const userDocRef = doc(db, "users", userDoc.id);
          await updateDoc(userDocRef, {
            role: "member"
          });
          console.log(`Added role 'member' to user ${userDoc.id}`);
        }
      });
  
      console.log("All users updated successfully.");
  
    } catch (error) {
      console.error("Error updating user roles:", error);
    }
  };