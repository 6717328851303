import React from "react";
import { greyDark, greyUltraLight } from "../../lib/colors";

export default function LocationBubbles({ activeIndex, displayLength, width }) {

    const divs = Array.from({ length: displayLength }, (_, index) => {
        const activeCircle = index === activeIndex;
        return (
            <div
                key={index}
                style={{
                    borderRadius: '50%',
                    backgroundColor: activeCircle ? greyDark : greyUltraLight,
                    width: width < 1400 ? width*0.0075 : '10px',
                    height: width < 1400 ? width*0.0075 : '10px',
                    marginRight: '3px',
                    marginLeft: '3px',
                    marginTop:'5px',
                }}
            />
        );
    });

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems:'center'
        }}>
            {divs}
        </div>
    );
};