import React, { useState } from "react";
import { TextInput } from "react-native-web";
import { Switch } from "react-native";
import {
    green,
    greyDark,
    greyLight,
    orange,
    white,
} from "../../../../lib/colors";
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiLockIcon from "@mui/icons-material/WifiLock";
import IonIcon from "@reacticons/ionicons";
import {
    Select,
    MenuItem,
    Alert, // Import Alert from Material UI
    Modal,
    Box,
    Typography,
    Button,
} from "@mui/material";
import {
    toggleOutletStatus,
    productSelectPress,
    submitNameEdit,
} from "../../../../functions/outletList";
import { sumArrayIgnoreNegatives } from "../../../../functions/wattHours";
import UsageDashboardContainer from "../../usageDashboardContainer";
import OutletModal from "../../../../components/outlet-modal";

function statusToBool(status) {
    return status === "On";
}

export default function Outlet({
    outlet,
    prodTypes,
    outlets,
    setOutlets,
    height,
    width,
    wattHours,
    rate,
    today
}) {
    const [prodType, setProdType] = useState(outlet.productType);
    const [isEnabled, setIsEnabled] = useState(statusToBool(outlet.status));
    const [outletName, setOutletName] = useState(outlet.name);
    const [nameEdit, setNameEdit] = useState(false);
    const [editTracker, setEditTracker] = useState(0);
    const [isHovered, setIsHovered] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showAlert, setShowAlert] = useState(false); // State to manage alert visibility

    const index = outlets.findIndex((obj) => obj.id === outlet.id);
    const wattHour = wattHours.filter(
        (wattHour) => wattHour.outletID === outlet.id && wattHour.date === today
    );
    
    const wattHourArray = wattHour[0]?.hourlyWattHour || [];
    const dailyUsage = sumArrayIgnoreNegatives(wattHourArray);

    const toggleStatus = async () => {
        toggleOutletStatus(isEnabled, outlet.orgID, outlet.id, outlet.docID);
        if (isEnabled) {
            outlets[index].status = "Off";
        } else {
            outlets[index].status = "On";
        };
        setOutlets(outlets);
        setIsEnabled(!isEnabled);
    };

    const submitEditName = async (name) => {
        if (name.length > 0) {
            let success = await submitNameEdit(outlet.orgID, outlet.id, outlet.docID, name);
            if (success) {
                outlets[index].name = name;
                setOutlets(outlets);
                setOutletName(name);
                setShowAlert(false); // Hide alert if successful
            } else {
                setShowAlert(true); // Show alert if name already taken
            }
        };
    };

    const selectOnPress = async (event) => {
        productSelectPress(outlet.productType, outlet.orgID, outlet.id, outlet.docID, event);
        if (event.target.value === "N/A") {
            outlets[index].productType = "";
            setOutlets(outlets);
            setProdType("");
        } else {
            outlets[index].productType = event.target.value;
            setOutlets(outlets);
            setProdType(event.target.value);
        };
    };

    const trackEdit = (e) => {
        e.stopPropagation(); // Prevent the event from propagating to the parent div
        if (editTracker === 0) {
            setNameEdit(!nameEdit);
            setEditTracker(1);
        } else {
            setEditTracker(0);
        };
    };

    const handleRowClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = (event) => {
        event.stopPropagation(); // Prevent the event from propagating to the parent
        setIsModalOpen(false);
    };

    const iconContainerStyle = {
        position: "relative",
        display: "inline-block",
    };

    const iconStyle = {
        width: width < 750 ? "15px" : "20px",
        cursor: "pointer",
        marginLeft: ".25vw",
        marginTop: ".225vw",
    };

    const hoverInfoStyle = {
        position: "absolute",
        bottom: "100%",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "#fff",
        padding: "5px",
        border: "1px solid #ccc",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        zIndex: 1,
        whiteSpace: "nowrap",
        display: isHovered ? "block" : "none",
    };

    //console.log(dailyUsage)

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column", // Changed from row to column to accommodate the alert
                width: "99%",
                alignItems: "center",
                alignSelf: 'center',
                borderRadius: "10px",
                marginTop: 10,
                boxShadow: "-1px 1px 1px 1px rgba(112, 112, 112, .375)",
                cursor: "pointer",
            }}
        >
            {showAlert && (
                <Alert
                    severity="warning"
                    onClose={() => setShowAlert(false)}
                    style={{ width: "50%", marginBottom: 10 }}
                >
                    The outlet name already exists. Please choose a different name.
                </Alert>
            )}

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    height: "50px",
                }}
            // onClick={handleRowClick}
            >
                <div
                    style={{
                        width: "25%",
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                    }}
                >
                    <button
                        onClick={(e) => {
                            e.stopPropagation(); // Prevent the event from propagating to the parent div
                            trackEdit(e);
                        }}
                        style={{
                            borderWidth: 0,
                            backgroundColor: "white",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                        }}
                    >
                        <IonIcon
                            name="create-outline"
                            style={{
                                width: width < 750 ? "25px" : "30px",
                                marginBottom: "65%",
                            }}
                        />
                    </button>
                    <div
                        style={{
                            fontWeight: "400",
                            fontSize: width < 750 ? "14px" : "18px",
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        {nameEdit ? (
                            <TextInput
                                defaultValue={outlet.name}
                                style={{
                                    alignSelf: "center",
                                    fontWeight: "100",
                                    fontSize: "18px",
                                    marginLeft: "1%",
                                    textAlign: "left",
                                    borderWidth: 2,
                                    borderRadius: 10,
                                    borderColor: "black",
                                    width: "90%",
                                    paddingLeft: 5,
                                }}
                                rows={1}
                                autoFocus={true}
                                maxLength={25}
                                enterKeyHint="done"
                                onChangeText={(name) => {
                                    setOutletName(name);
                                }}
                                onSubmitEditing={() => {
                                    setNameEdit(false);
                                    submitEditName(outletName);
                                }}
                                onBlur={() => {
                                    if (editTracker === 1) {
                                        setNameEdit(false);
                                    }
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        trackEdit(e);
                                        submitEditName(outletName);
                                    }
                                }}
                            />
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {outlet.name}
                                <div
                                    style={iconContainerStyle}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    <IonIcon name="information-circle-outline" style={iconStyle} onClick={handleRowClick} />
                                    <div style={hoverInfoStyle}>Click for more information</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        width: "25%",
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "90%",
                    }}
                >
                    <Select
                        value={outlet.productType ? outlet.productType : "N/A"}
                        onChange={selectOnPress}
                        sx={{
                            color: outlet.productType ? "black" : "orange",
                            ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "white",
                            },
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                            width: "100%",
                            height: "90%",
                            fontSize: width < 750 ? "14px" : "18px",
                        }}
                    >
                        {Array.from(new Set(prodTypes)).map((option) => (
                            <MenuItem value={option} key={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                {outlet.wifiStatus === "Connected" && (
                    <div
                        style={{
                            width: width > 805 ? "25%" : "20%",
                            display: "flex",
                            flexDirection: "row",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            color: green,
                        }}
                    >
                        {outlet.wifiStatus}
                        <WifiIcon style={{ marginLeft: "3%", color: green }} />
                    </div>
                )}
                {outlet.wifiStatus === "Disconnected" && (
                    <div
                        style={{
                            width: width > 805 ? "25%" : "20%",
                            display: "flex",
                            flexDirection: "row",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            color: greyDark,
                        }}
                    >
                        {outlet.wifiStatus}
                        <WifiOffIcon style={{ marginLeft: "3%", color: greyDark }} />
                    </div>
                )}
                {outlet.wifiStatus === "Not activated" && (
                    <div
                        style={{
                            width: width > 805 ? "25%" : "20%",
                            display: "flex",
                            flexDirection: "row",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "orange",
                        }}
                    >
                        {outlet.wifiStatus}
                        <WifiLockIcon style={{ marginLeft: "3%", color: "orange" }} />
                    </div>
                )}
                <div
                    style={{
                        width: "20%",
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {(dailyUsage / 1000).toFixed(2)} kWh
                </div>
                <div
                    style={{
                        width: "10%",
                        display: "flex",
                        flexDirection: "row",
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {outlet.wifiStatus === "Connected" && (
                        <Switch
                            style={{
                                display: "Flex",
                            }}
                            activeThumbColor={white}
                            thumbColor={white}
                            activeTrackColor={green}
                            trackColor={greyLight}
                            onValueChange={toggleStatus}
                            value={statusToBool(outlet.status)}
                        />
                    )}
                </div>
            </div>

            <OutletModal
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                outlet={outlet}
                dailyUsage={dailyUsage}
                rate={rate}
                selectOnPress={selectOnPress}
                submitEditName={submitEditName}
                width={width}
                height={height}
                outlets={outlets}
                setOutlets={setOutlets}
            />
        </div>
    );
}
