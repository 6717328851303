import { Dimensions } from 'react-native';

//based on iphone 12 pro sizes, update to computer
const guidelineBaseWidth = 390;
const guidelineBaseHeight = 844;
export const height = Dimensions.get('window').height;
export const width = Dimensions.get('window').width;
let wideScale = (size) => (width / guidelineBaseWidth) * size;
if (width > 405) {
    wideScale = (size) => (width / guidelineBaseWidth) * size * .93;
}
let vertical = (size) => (height / guidelineBaseHeight) * size;
if (height < 685) {
    vertical = (size) => (height / guidelineBaseHeight) * size * .96;
}
export const scale = wideScale;
export const verticalScale = vertical;
export const moderateScale = (size, factor = 0.5) => size + (scale(size) - size) * factor;
// console.log(height);

export default {
    height,
    width,
    scale,
    verticalScale,
    moderateScale,
};
