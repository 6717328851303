import { ResponsiveLine, Line } from "@nivo/line";
import { green } from "../../lib/colors";
import { ResponsiveBar } from "@nivo/bar";

const getColor = (line) => {
    if (line.id === 'series1') return green;
    if (line.id === 'series2') return 'hsl(106, 70%, 50%)';
    return green; // default color
};

export const LineChart = ({ data, averageData, width }) => (
    <ResponsiveLine
        data={data}
        margin={{ top: 10, left: 40, bottom: width < 750 ? 35 : 25 }}
        padding={0.4}
        xScale={{
            type: 'linear',
            min: 0,
            max: 23,
        }}
        yScale={{
            type: 'linear',
            max: data[0].data.map(time => time.y)
            .reduce((max, current) => Math.max(max, current), -Infinity)*1.25
        }}
        curve="basis"
        colors={(d) => d.color}
        enableArea={true}
        areaOpacity={0.1}
        enablePoints={false}
        isInteractive={false}
        layers={[
            'grid',
            'axes',
            'lines',
            'areas',
            (props) => {
                if (averageData) {
                    return (
                        <path
                            key={averageData.id}
                            d={props.lineGenerator(averageData.data.map(d => ({
                                x: props.xScale(d.x),
                                y: props.yScale(d.y),
                              })))}
                            fill={'none'}//{props.areaGenerator(series2.data.map(d => d.position))}
                            stroke={averageData.color}
                            strokeDasharray="5, 5" // Dashed line pattern
                            strokeWidth={2}
                        />
                    );
                }
                return null;
            }
        ]}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: width < 1000 ? -30 : 0,
            format: (value) => {
                const hours = value % 24;
                const period = hours >= 12 ? 'PM' : 'AM';
                const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
                return `${formattedHours}:00`//`${formattedHours} ${period}`;
            },
        }}
        axisLeft={{
            tickSize: 0,
            legend: 'kWh',
            legendPosition: 'middle',
            legendOffset: -35,
            truncateTickAt: 0,
            format: value => (value / 1000).toFixed(2)
        }}
    />
);

export const CustomTooltip = ({ id, value, indexValue, color }) => (
    <div
        style={{
            padding: '12px',
            background: 'white',
            border: '1px solid #ccc',
            color: 'black',
        }}
    >
        <strong>{id}</strong>
        <br />
        {(value / 1000).toFixed(2)} kWh
    </div>
);

export const AverageLine = ({ bars, yScale, average, innerWidth }) => {
    // scaling and average feels weird, but this puts it at the right place
    // console.log('average average', average);
    if (average !== null && average !== undefined && average !== NaN) {
        const averageY = Math.abs(yScale(average));
        // console.log('y', averageY);
        return (
            <line
                x1={0}
                x2={innerWidth}
                y1={averageY}
                y2={averageY}
                stroke="red"
                strokeWidth={2}
                strokeDasharray="4 4"
            />
        );
    } else {
        return null;
    };
};

export const StackedBar = ({ data, index, axisBottomFormat, average, graphKeys, height, width }) => {
    let maxY = 0
    data.map((time) => {
        let sum = Object.values(time).reduce((acc, val) => acc + Number(val), 0);
        if(sum > maxY){
            maxY = sum
        }
    })
    return (
        <ResponsiveBar
            data={data}
            keys={graphKeys}
            indexBy={index}
            margin={{ top: 10, left: 40, bottom: width < 750 ? 35 : 25 }}
            padding={0.4}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'set2' }}
            colorBy="id"
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            maxValue = {maxY*1.25}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                //tickPadding: 5,
                tickRotation: width < 750 ? -15 : 0,
                // legend: 'week',
                //legendPosition: 'middle',
                //legendOffset: 32,
                //truncateTickAt: 0,
                format: axisBottomFormat
            }}
            axisLeft={{
                tickSize: 0,
                //tickPadding: 5,
                //tickRotation: 0,
                legend: 'kWh',
                legendPosition: 'middle',
                legendOffset: -30,
                truncateTickAt: 0,
                format: value => (value / 1000)
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        1.6
                    ]
                ]
            }}
            legends={[]}
            enableLabel={false}
            tooltip={({ id, value, indexValue, color }) => (
                <CustomTooltip id={id} value={value} indexValue={indexValue} color={color} />
            )}
            layers={[
                'grid',
                'axes',
                'bars',
                'markers',
                'legends',
                ({ bars, yScale, innerWidth }) => (
                    <AverageLine
                        bars={bars}
                        yScale={yScale}
                        average={average}
                        innerWidth={innerWidth}
                    />
                )
            ]}
        />
    )
};