import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  MenuItem,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Tooltip,
} from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  assignDeviceToOrganization,
  createOutletsInOrganization,
  deleteNewDevice,
} from "../../functions/inventory";
import { green } from "../../lib/colors";
const renderCellContent = (params) => {
  const hasNoData = params.value === "No data";
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      {params.value}
      {hasNoData && <ReportProblemIcon style={{ color: "grey" }} />}
    </div>
  );
};

const ActionCell = ({ id, onDelete, onAdd }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "right",
      alignItems: "center",
      width: "100%",
      height: "100%",
      gap: "10px",
    }}
  >
    <Tooltip title="Add to organization">
      <GroupAddIcon
        style={{ cursor: "pointer", color: green }}
        onClick={() => onAdd(id)}
      />
    </Tooltip>
    <Tooltip title="Delete from list">
      <DeleteIcon
        style={{ cursor: "pointer", color: "red", marginRight: "15px" }}
        onClick={() => onDelete(id)}
      />
    </Tooltip>
  </Box>
);

const InventoryDataGridTable = ({
  data,
  loading,
  organizations,
  getNewDevices,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState("");

  const handleDeleteClick = (id) => {
    setCurrentId(id);
    setDeleteDialogOpen(true);
  };

  const handleAssignClick = (id) => {
    setCurrentId(id);
    setAssignDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    console.log(`Deleting device with id: ${currentId}`);
    deleteNewDevice(currentId);
    getNewDevices();
    setDeleteDialogOpen(false);
  };

  const handleAssignOrganizationConfirm = async () => {
    setAssignDialogOpen(false);
    try {
      const activationDate = getCurrentDateFormatted();
      console.log(
        `Organization option: ${selectedOrganization} for device with id: ${currentId}`
      );

      await assignDeviceToOrganization(selectedOrganization, currentId);
      await createOutletsInOrganization(
        selectedOrganization,
        currentId,
        activationDate
      );
      await deleteNewDevice(currentId);
      await getNewDevices();
    } catch (error) {
      console.error("Error handling organization assignment:", error);
    }
  };

  const getCurrentDateFormatted = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
    const day = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const rows = Object.values(data).map((device) => ({
    id: device.id,
    name: device.device,
    location: device.Location,
  }));

  const columns = [
    {
      field: "name",
      headerName: "Device Name",
      flex: 1,
      renderCell: renderCellContent,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      renderCell: renderCellContent,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <ActionCell
          id={params.id}
          onAdd={handleAssignClick}
          onDelete={handleDeleteClick}
        />
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h5" component="div" sx={{ mb: 2 }}>
        Inventory
      </Typography>
      <Box sx={{ width: "50%", height: "80vh" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          loading={loading}
          disableExtendRowFullWidth
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f5f5f5",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              },
            },
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: 'name', sort: 'asc' }],
            },
          }}
        ></DataGrid>
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this device?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={assignDialogOpen}
        onClose={() => setAssignDialogOpen(false)}
      >
        <DialogTitle>Add to organization</DialogTitle>
        <DialogContent>
          <Select
            value={selectedOrganization}
            onChange={(e) => setSelectedOrganization(e.target.value)}
            fullWidth
          >
            {organizations.map((org) => (
              <MenuItem key={org.uid.stringValue} value={org.uid.stringValue}>
                {org.name.stringValue}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAssignDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleAssignOrganizationConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default InventoryDataGridTable;
