import React from "react";
//import StackedBar from "../../../../../components/chart/stackedBar";
import chartImage from '../../../../../assets/images/pieChart.png';
import { sumHoursByOutletName, filterByDateRange } from "../../../../../functions/wattHours";
import { StackedBar } from "../../../../../components/newGraphLibrary/graphs";
import { GraphHeader } from "../../../../../components/graphHeader";

export default function StackedWeeklyUsagePresentational({
    outlets,
    wattHours,
    height,
    width,
}) {
    const weeklyLabels = {
        0: 'Last Week',
        1: '2 Weeks Ago',
        2: '3 Weeks Ago',
        3: '4 Weeks Ago',
        4: '5 Weeks Ago'
    };
    //for setting the timelimit to query
    const today = new Date();
    const dayOfWeek = today.getDay();
    const previousMonday = new Date(today);
    previousMonday.setDate(today.getDate() - (dayOfWeek + 6) % 7);
    const previousSunday = new Date(today);
    previousSunday.setDate(today.getDate() - dayOfWeek); // Set to current 
    const monday_arr = [];
    const sunday_arr = [];
    const mon = []
    const sun = []
    // Start from the Monday before the previous Monday
    let mondayStart = new Date(previousMonday);
    mondayStart.setDate(previousMonday.getDate() - 7); // Skip the most recent Monday
    for (let i = 0; i < 5; i++) {
        const year = mondayStart.getFullYear();
        const month = String(mondayStart.getMonth() + 1).padStart(2, '0');
        const day = String(mondayStart.getDate()).padStart(2, '0');
        monday_arr.push(`${year}-${month}-${day}`);

        const sunday = new Date(mondayStart);
        sunday.setDate(mondayStart.getDate() + 6);
        const sun_year = sunday.getFullYear();
        const sun_month = String(sunday.getMonth() + 1).padStart(2, '0');
        const sun_day = String(sunday.getDate()).padStart(2, '0');
        sunday_arr.push(`${sun_year}-${sun_month}-${sun_day}`);

        // Move to the Monday of the previous week
        mondayStart.setDate(mondayStart.getDate() - 7);
    };

    const weeklyBottomAxisLabel = (value) => weeklyLabels[value] || value;

    const weekMinusFourWattHours = sumHoursByOutletName(filterByDateRange(wattHours, monday_arr[4], sunday_arr[4]));
    const weekMinusThreeWattHours = sumHoursByOutletName(filterByDateRange(wattHours, monday_arr[3], sunday_arr[3]));
    const weekMinusTwoWattHours = sumHoursByOutletName(filterByDateRange(wattHours, monday_arr[2], sunday_arr[2]));
    const weekMinusOneWattHours = sumHoursByOutletName(filterByDateRange(wattHours, monday_arr[1], sunday_arr[1]));
    const weekMinusZeroWattHours = sumHoursByOutletName(filterByDateRange(wattHours, monday_arr[0], sunday_arr[0]));
    const weeklyUsageArray = [
        weekMinusZeroWattHours,
        weekMinusOneWattHours,
        weekMinusTwoWattHours,
        weekMinusThreeWattHours,
        weekMinusFourWattHours,
    ];

    let barData = [];
    let graphKeys = [];
    outlets.forEach((outlet, index) => {
        graphKeys.push(outlet.name)
    });
    weeklyUsageArray.forEach((week, index) => {
        const object = {
            week: `${index}`,
        };
        Object.keys(week).forEach((key, index) => {
            object[key] = week[key].toFixed(2);
        });
        barData[4 - index] = object;
    });
    let averageWeek = [0, 0, 0, 0, 0]
    let week_count = 0
    weeklyUsageArray.forEach((week, index) => {
        for (const outlet in week) {
            averageWeek[index] += week[outlet];
        };
        if(averageWeek[index] != 0){
            week_count+=1
        }
    });
    const averageWeekAverage = (
        averageWeek[0] +
        averageWeek[1] +
        averageWeek[2] +
        averageWeek[3] +
        averageWeek[4]
    ) / week_count;

    let percentChange = 0;
    if (averageWeek[0] !== 0 && averageWeek[1] !== 0) {
        percentChange = (((averageWeek[0] - averageWeek[1]) / averageWeek[1]) * 100).toFixed(2);
    } else {
        percentChange = 0
    };

    if (wattHours.length !== 0 && week_count !== 0) {   
        return (
            <div>
                <GraphHeader percentChange={percentChange} type={'weekly'} weeklyAverage={averageWeekAverage} width={width} />
                <div style={{
                    width: '100%',
                    height: width < 1400 ? width*0.29 : '410px',
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '-5px',
                }}>
                    <StackedBar
                        data={barData}
                        index={'week'}
                        axisBottomFormat={weeklyBottomAxisLabel}
                        average={averageWeekAverage}
                        graphKeys={graphKeys}
                        height={height}
                        width={width}
                    />
                </div>
            </div>
        )
    } else {
        return (
            <div style={{
                height: width < 1400 ? width*0.29 : '410px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <div style={{
                    alignItems: 'center',
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <img
                            src={chartImage}
                            alt="pie chart"
                            style={{
                                width: 'auto',
                                height: width < 1400 ? width*0.175 : '250px',
                            }}
                        />
                    </div>
                    <p
                        style={{
                            fontSize: width < 1050 ? width*0.025 : '27px',
                            fontFamily: 'Manrope',
                            fontWeight: '600',
                            marginTop: '2%',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        No Chart Data
                    </p>
                    <p
                        style={{
                            textAlign: 'center',
                            fontFamily: 'Manrope',
                            marginTop: '1%',
                            fontSize: width < 1050 ? width*0.02 : '22px',
                        }}>
                        Check back later to see weekly usage.
                    </p>
                </div>
            </div>
        );
    };
};