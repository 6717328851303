import React, { useEffect, useState } from "react";
import { greyLight } from "../../../lib/colors";
import OutletStatusListContainer from "./outletStatusListContainer";
import StackedUsageContainer from "./stackedUsageContainer";
import SavingsContainer from "./savingsContainer";
import HourlyUsageGraphContainer from "./hourlyUsageGraphContainer";
import OutletDataContainer from "./outletDataContainer";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";


export default function UsageDashboardContainer({
  orgID,
  outlets,
  wattHours,
  outletAverageUsage,
  height,
  width,
  singleOutlet = false,
  outlet = null,
  selectedApplianceType = null,
  handleApplianceTypeChange = null,
  prodTypes = null,
  rate,
}) {
  //Add outlet names to the wattHours array of objects for the chart tooltips
  // Step 1: Convert outlets to a Map for fast lookup
  const idToNameMap = new Map(outlets.map((obj) => [obj.id, obj.name]));

  // Step 2: Assign names from outlets to wattHours based on id
  wattHours.forEach((obj) => {
    if (idToNameMap.has(obj.outletID)) {
      obj.name = idToNameMap.get(obj.outletID);
    };
  });

  useEffect(() => {
    if(singleOutlet) console.log("width right now: ", width )
  }, [width]);

  return (
    <div style={{
      display: "flex",
      alignItems:'center',
      flexDirection: "column",
      width: width < 1400 ? width*0.80 : "1350px",
    }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: width < 1400 ? width*.456 : "650px",
            width: "55%",
          }}
        >
          <StackedUsageContainer
            outlets={outlets}
            wattHours={wattHours}
            height={height}
            width={width}
            selectedApplianceType={selectedApplianceType}
            handleApplianceTypeChange={handleApplianceTypeChange}
            prodTypes={prodTypes}
          />
          {singleOutlet ? (
            <OutletDataContainer outlet={outlet} width={width} />
          ) : (
            <OutletStatusListContainer
              outlets={outlets}
              height={height}
              width={width}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            height: width < 1400 ? width*.456 : "650px",
          }}
        >
          {singleOutlet ? (
            <SavingsContainer
              orgID={orgID}
              outlets={outlets}
              rate={rate}
              height={height}
              width={width}
              outletID={outlet.id}
            />
          ) : (
            <SavingsContainer
              orgID={orgID}
              outlets={outlets}
              rate={rate}
              height={height}
              width={width}
            />
          )}
          <HourlyUsageGraphContainer
            orgID={orgID}
            outlets={outlets}
            outletAverageUsage={outletAverageUsage}
            wattHours={wattHours}
            height={height}
            width={width}
          />
          </div>
      </div>
      {prodTypes && handleApplianceTypeChange && (
        <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems:'center',
            //backgroundColor:'red',
          }}>
          <FormHelperText style={{fontSize: width < 1300 ? 12.5: 15, fontWeight:'500',}}>
            Filter data above by product type:
          </FormHelperText>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <Select
              defaultValue={'All Outlets'}
              inputProps={{
                name: 'age',
                id: 'uncontrolled-native',
              }}
              onChange={handleApplianceTypeChange}
            >
              <MenuItem value="All Outlets">
                <em>All Outlets</em>
              </MenuItem>
              {prodTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>)}
    </div>
  );
}
