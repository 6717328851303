import {
  CLEAR_LOGOUT_ERROR,
  LOGIN_INIT,
  LOGIN_ERROR,
  LOGOUT_INIT,
  LOGIN_SUCCESS,
  LOGOUT_ERROR,
  LOGOUT_SUCCESS,
} from '../../constants/action-types';
import {DEFAULT_STATE} from '../../lib/state-utils';

export default function authReducer(state = DEFAULT_STATE.auth, action) {
  switch (action.type) {
    case LOGIN_INIT:
    case LOGOUT_INIT:
      return {...state, error: '', loading: true};
    case LOGIN_ERROR:
    case LOGOUT_ERROR:
      return {...state, isLoggedIn: false, error: action.payload.error, loading: false};
    case LOGIN_SUCCESS:
      return {...state, isLoggedIn: true, loading: false};
    case LOGOUT_SUCCESS:
      return {...state, isLoggedIn: false, loading: false};
    case CLEAR_LOGOUT_ERROR:
      return {...state, error: ''};
    default:
      return state;
  }
}
