import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  setDoc,
  updateDoc
} from "firebase/firestore";
import db from "../firebase";
import moment from "moment";
import { uid } from "../firebase";

export const fetchOrgID = async () => {
  let orgID_helper = null;
  let timezone_helper = "UTC";
  let rate_helper = "0.16";
  try {
    let querySnapshot = await getDocs(collection(db, "organizations"));
    querySnapshot.forEach((doc) => {
      if (doc.data().users.includes(uid)) {
        orgID_helper = doc.id;
        timezone_helper = doc.data().timezone;
        rate_helper = doc.data().rate;
        return {
          orgID: orgID_helper,
          timezone: timezone_helper,
          rate: rate_helper,
        };
      }
    });
  } catch (error) {
    console.error("Error fetching document:", error);
  }
  return { orgID: orgID_helper, timezone: timezone_helper, rate: rate_helper };
};

//note: might not need this and do everything inside fetchOrgID function
export const fetchOrganizationIDs = (setAllOrgs) => {
  const orgList = [];
  const getOrganizationIDs = async () => {
    let organizationIDs;
    //note: leaving because of the indexing thing
    organizationIDs = await getDocs(collection(db, "organizations"));

    const orgIDQ = query(collection(db, "organizations"));
    const orgIDsSnapshot = await getDocs(orgIDQ);
    orgIDsSnapshot.forEach((doc) => {
      const docData = doc._document.data.value.mapValue.fields;
      // console.log(docData, docData.name?.stringValue);
      orgList.push(docData);
    });
    setAllOrgs(orgList);
  };
  getOrganizationIDs();
};

export const fetchSpecificTeamOrganizationIDs = (orgID, setAllOrgs) => {
  const orgList = [];
  const getOrganizationIDs = async () => {
    let organizationIDs;
    //note: leaving because of the indexing thing
    organizationIDs = await getDoc(doc(db, "organizations", orgID));

    const orgIDQ = query(
      collection(db, "organizations", orgID, "organizations")
    );
    const orgIDsSnapshot = await getDocs(orgIDQ);
    orgIDsSnapshot.forEach((doc) => {
      const docData = doc._document.data.value.mapValue.fields;
      // console.log(docData, docData.name?.stringValue);
      orgList.push(docData);
    });
    setAllOrgs(orgList);
  };
  getOrganizationIDs();
};

export const orgSelectPress = (
  pickedOrg,
  event,
  setPickedOrg,
  setOrgEdit,
  setSelectedOrgID,
  setTimezone,
  setRate = null
) => {
  const previousOrg = pickedOrg;
  const displayOrg = event.target.value;
  setPickedOrg(displayOrg);
  setSelectedOrgID(displayOrg);
  localStorage.setItem("selectedOrgID", displayOrg);
  //setOrgName(orgName);
  const getSelectedTimezone = async () => {
    const orgTimezoneQ = query(
      collection(db, "organizations"),
      where("uid", "==", displayOrg)
    );
    const orgTimezoneSnapshot = await getDocs(orgTimezoneQ);
    orgTimezoneSnapshot.forEach((doc) => {
      let orgTimezone = doc.data().timezone;
      let orgRate = doc.data().rate;
      localStorage.setItem("timezone", orgTimezone);
      localStorage.setItem("rate", orgRate);
      setTimezone(orgTimezone);
      if (setRate) setRate(orgRate);
    });
  };
  getSelectedTimezone();
  setOrgEdit(false);
};

export const fetchOrganizationFromID = async (orgID) => {
  const orgRef = doc(db, "organizations", orgID);
  const orgDoc = await getDoc(orgRef);
  if (orgDoc.exists()) {
    console.log("Document data:", orgDoc.data());
    return orgDoc.data();
  } else {
    console.log("No such document!");
    return null;
  }
};

export const fetchProductTypesFromOrganization = async (
  orgID,
  setProdTypes
) => {
  try {
    const outletsRef = collection(db, `organizations/${orgID}/Outlets`);
    const querySnapshot = await getDocs(outletsRef);
    const productTypes = new Set();

    if (querySnapshot.empty) {
      console.log("There are no outlets.");
      setProdTypes(["All Outlets"]);
      return;
    }

    querySnapshot.forEach((doc) => {
      const outletData = doc.data();
      if (outletData.productType) {
        productTypes.add(outletData.productType);
      } else {
        productTypes.add("N/A");
      }
    });

    setProdTypes(Array.from(productTypes));
  } catch (error) {
    console.error("Error retrieving documents: ", error);
    setProdTypes(["All Outlets"]);
  }
};

export const fetchIsOverviewAccount = async (orgID) => {
  const orgRef = doc(db, "organizations", orgID);
  const orgDoc = await getDoc(orgRef);
  if (orgDoc.exists()) {
    const orgData = orgDoc.data();
    return (
      orgData.child_orgs &&
      Array.isArray(orgData.child_orgs) &&
      orgData.child_orgs.length > 0
    );
  } else {
    console.log("No such document!");
    return false;
  }
};

export const registerOrganization = async (organization) => {
  try {
    // Format the join date
    const formattedJoinDate = moment().format("YYYY-MM-DD");
    organization.joinDate = formattedJoinDate;

    const orgDocRef = doc(collection(db, "organizations"));
    organization.uid = orgDocRef.id;
    const zipcode_to_timezone = require("zipcode-to-timezone");
    const timezone = zipcode_to_timezone.lookup(organization.zipcode);

    // Set the document in Firestore with the organization data
    await setDoc(orgDocRef, {
      name: organization.name,
      rate: organization.rate,
      timezone: timezone,
      zipcode: organization.zipcode,
      address: organization.address,
      users: organization.users,
      joinDate: organization.joinDate,
      uid: organization.uid,
    });
    await setUsersOrgID(organization.uid, organization.users);
    console.log(
      "Organization successfully registered with ID:",
      organization.uid
    );
  } catch (error) {
    console.error("Error registering organization:", error);
    throw new Error("Failed to register organization.");
  }
};

const setUsersOrgID = async (orgID, users) => {
  try {
    for (const uid of users) {
      const userDocRef = doc(db, "users", uid);
      await updateDoc(userDocRef, {
        orgID: orgID,
      });

      console.log(`User ${uid} successfully updated with orgID: ${orgID}`);
    }
  } catch (error) {
    console.error("Error updating user documents with orgID:", error);
    throw new Error("Failed to update user documents with orgID.");
  }
};
