import React, { useEffect } from 'react';
import { View } from 'react-native-web';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import WiredSchedulesList from './scheduleListContainer';
import Header from '../../components/header';
import {useLocation} from 'react-router-dom';
import { fetchOrgID } from "../../functions/organization";
import ScheduleTable from './scheduleNewView'

export default function ScheduleScreen() {
    const [user, waiting, error] = useAuthState(auth);
    const navigate = useNavigate();
    const location = useLocation();
    const orgID = localStorage.getItem("orgID");
    // console.log(location.state);
    useEffect(() => {
        if (waiting) {
            // maybe trigger a loading screen
            return;
        }
        if (!user) navigate("/login");
    }, [user, waiting]);

    return (
        <>
            {user &&
                <View style={{
                    marginTop: 75,
                }}>
                    <Header orgID={orgID}/>
                    {/* <WiredSchedulesList
                        uid={user.uid}
                        orgID = {orgID}
                    /> */}
                    <ScheduleTable></ScheduleTable>
                </View>
            }
        </>
    )
}