import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logout } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import logo from "../../assets/images/logo.png";
import { green, greyDark } from "../../lib/colors";
import { fetchUserRole } from "../../functions/rolesManagement";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Header = (orgID) => {
  const [userRole, setUserRole] = useState("");
  const [timezoneConversion, setTimezoneConversion] = useState(0);
  const [user, waiting] = useAuthState(auth);
  orgID = orgID.orgID;
  const navigate = useNavigate();
  const pathParts = window.location.pathname.split("/");
  const page = pathParts[pathParts.length - 1];
  const toggleMenu = (event) => {
    setActivePage(event.target.textContent);
    let pageName = event.target.textContent.toLowerCase();
    if (pageName === "usage dashboard") {
      pageName = "";
    }
    if (pageName === "missions") {
      pageName = "schedules";
    }
    const state = { orgID: orgID };
    navigate("/" + pageName, { state: state });
  };
  const [activePage, setActivePage] = useState(page);
  useEffect(() => {
    if (waiting) {
      // maybe trigger a loading screen
      return;
    }
    if (!user) navigate("/login");
  }, [navigate, user, waiting]);
  useEffect(() => {
    //console.log('page', page);
    if (page === "") {
      setActivePage("Usage Dashboard");
    } else {
      setActivePage(page);
    }
  }, [activePage, page]);

  useEffect(() => {
    // Fetch the user's role when the user is authenticated
    const fetchRole = async () => {
      if (user) {
        const role = await fetchUserRole(user.uid);
        if (role) {
          setUserRole(role);
        }
      }
    };
    fetchRole();
  }, [user]);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <header
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 10,
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          backgroundColor: "white",
          //marginLeft: "1%",
          //marginRight: "1%",
          height: "70px",
        }}
      >
        <img
          src={logo}
          alt="ReVert Logo"
          style={{
            width: windowDimensions.width < 800 ? "25vw" : "200px",
            height: "auto",
          }}
        />
        {/*<Link to="/">*/}
        <button
          onClick={toggleMenu}
          style={{
            border: "none",
            fontSize: windowDimensions.width < 850 ? "2.25vw" : "22px",
            backgroundColor: "transparent",
            borderBottom: `2px solid`,
            borderColor: activePage === "Usage Dashboard" ? green : "black",
            color: activePage === "Usage Dashboard" ? green : "black",
            cursor: "pointer",
          }}
        >
          Usage Dashboard
        </button>
        {/*</Link>*/}
        <button
          onClick={toggleMenu}
          style={{
            border: "none",
            fontSize: windowDimensions.width < 850 ? "2.25vw" : "22px",
            backgroundColor: "transparent",
            borderBottom: `2px solid`,
            borderColor:
              activePage === "Schedules" ||
              activePage === "schedules" ||
              activePage === "Missions"
                ? green
                : "black",
            color:
              activePage === "Schedules" ||
              activePage === "schedules" ||
              activePage === "Missions"
                ? green
                : "black",
            cursor: "pointer",
          }}
        >
          Schedules
        </button>
        {orgID === "LZqI3R6MInUuwtgtROPK" && (
          <>
            <button
              onClick={toggleMenu}
              style={{
                border: "none",
                fontSize: windowDimensions.width < 850 ? "2.25vw" : "22px",
                backgroundColor: "transparent",
                borderBottom: `2px solid`,
                borderColor: activePage === "outlets" ? green : "black",
                color: activePage === "outlets" ? green : "black",
                cursor: "pointer",
              }}
            >
              Outlets
            </button>
            <button
              onClick={toggleMenu}
              style={{
                border: "none",
                fontSize: windowDimensions.width < 850 ? "2.25vw" : "22px",
                backgroundColor: "transparent",
                borderBottom: `2px solid`,
                borderColor: activePage === "inventory" ? green : "black",
                color: activePage === "inventory" ? green : "black",
                cursor: "pointer",
              }}
            >
              Inventory
            </button>
            <button
              onClick={toggleMenu}
              style={{
                border: "none",
                fontSize: windowDimensions.width < 850 ? "2.25vw" : "22px",
                backgroundColor: "transparent",
                borderBottom: `2px solid`,
                borderColor: activePage === "organizations" ? green : "black",
                color: activePage === "organizations" ? green : "black",
                cursor: "pointer",
              }}
            >
              Organizations
            </button>
          </>
        )}
        {(userRole === "admin" || userRole === "owner") && (
          <button
            onClick={toggleMenu}
            style={{
              border: "none",
              fontSize: windowDimensions.width < 850 ? "2.25vw" : "22px",
              backgroundColor: "transparent",
              borderBottom: `2px solid`,
              borderColor: activePage === "Users" ? green : "black",
              color: activePage === "Users" ? green : "black",
              cursor: "pointer",
            }}
          >
            Users
          </button>
        )}

        <Link>
          <button
            onClick={() => {
              if (user) {
                logout();
                setActivePage("Sign In");
                localStorage.clear();
              } else {
                toggleMenu();
              }
            }}
            style={{
              border: "none",
              fontSize: windowDimensions.width < 850 ? "2.25vw" : "22px",
              backgroundColor: user ? greyDark : green,
              color: "white",
              borderRadius: "50px",
              width: windowDimensions.width < 850 ? "15vw" : "150px",
              height: windowDimensions.width < 850 ? "5vw" : "45px",
              cursor: "pointer",
            }}
          >
            Sign Out
          </button>
        </Link>
      </header>
    </>
  );
};
export default Header;
