import {combineReducers} from 'redux';
// import outletsReducer from './outlets';
// import groupsReducer from './groups';
import {HYDRATE} from '../constants/action-types';
// import userReducer from './user';
import authReducer from './auth';
// import rulesReducer from './rules';
// import missionsReducer from './missions';
// import usageDataReducer from './usage';
// import idleLoadDataReducer from './idle-load';
// import ecoStatsReducer from './eco-stats';
// import emissionsReducer from './emissions';
// import globalReducer from './global';

const reducers = combineReducers({
  auth: authReducer,
  // user: userReducer,
  // outlets: outletsReducer,
  // usage: usageDataReducer,
  // idleLoad: idleLoadDataReducer,
  // ecoStats: ecoStatsReducer,
  // emissions: emissionsReducer,
  // groups: groupsReducer,
  // rules: rulesReducer,
  // missions: missionsReducer,
  // global: globalReducer,
});

export default function rootReducer(state = {}, action) {
  return action.type === HYDRATE ? action.payload : reducers(state, action);
}
