import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  Grid,
  Button,
  DialogActions,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from "@mui/icons-material/Circle";
import { green, greyDark, orange } from "../../lib/colors";
import { deleteOrgSchedule, updateStatus } from "../../functions/scheduleTable";

const ScheduleDialog = ({
  open,
  handleClose,
  schedule,
  formatHour,
  onDelete,
  onStatusChange
}) => {
  const [openConfirm, setOpenConfirm] = useState(false);

  const getOutletsNames = (scheduleOutlets) => {
    const outlets = JSON.parse(localStorage.getItem("outlets") || "[]");

    return scheduleOutlets.map((outletId) => {
      const outlet = outlets.find((o) => o.id === outletId);

      return outlet ? outlet.name : "Unknown Outlet";
    });
  };

  const handleDelete = () => {
    setOpenConfirm(true);
  };

  const handleConfirmDelete = () => {
    deleteOrgSchedule(schedule.id);
    setOpenConfirm(false);
    handleClose();
    onDelete(schedule);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleStatusChange = (status) => {
    updateStatus(schedule.id,status );
    schedule.status = status
    handleClose();
    onStatusChange(schedule)
    if(status === "Rejected"){
        onDelete(schedule);
    };
  };

  if (schedule) {
    return (
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>
          <Typography fontWeight={"fontWeightBold"} gutterBottom>
            Schedule Details
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={5}>
              <Typography
                fontWeight={"fontWeightBold"}
                variant="h7"
                gutterBottom
              >
                Outlets
              </Typography>
              <List
                sx={{
                  maxHeight: 300,
                  overflow: "auto",
                  bgcolor: "background.paper",
                }}
              >
                {getOutletsNames(schedule.outletsSelected).map(
                  (outletName, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>
                        <CircleIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary={outletName} />
                    </ListItem>
                  )
                )}
              </List>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography fontWeight={"fontWeightBold"} color={green}>
                    Power Off
                  </Typography>
                  <Typography>{formatHour(schedule.offHour)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight={"fontWeightBold"} color={green}>
                    Power On
                  </Typography>
                  <Typography>{formatHour(schedule.onHour)}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight={"fontWeightBold"} color={green}>
                    During
                  </Typography>
                  <Typography>{schedule.repeat.join(", ")}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight={"fontWeightBold"} color={green}>
                    Schedule Status
                  </Typography>
                  <Typography
                    color={schedule.status === "Accepted" ? green : orange}
                  >
                    {schedule.status}
                  </Typography>
                </Grid>
                {schedule.status === "New" && (
                  <Grid item xs={12}>
                    <Button
                      onClick={()=>{handleStatusChange("Accepted")}}
                      variant="contained"
                      size="small"
                      sx={{ backgroundColor:green, mr:1 }}
                    >
                      Accept
                    </Button>
                    <Button
                      onClick={()=>{handleStatusChange("Rejected")}}
                      variant="contained"
                      size="small"
                      sx={{ backgroundColor:greyDark }}
                    >
                      Reject
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<DeleteIcon />}
            color="error"
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
        <Dialog open={openConfirm} onClose={handleCloseConfirm}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete this schedule?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmDelete} color="error">
              Delete
            </Button>
            <Button onClick={handleCloseConfirm} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Dialog>
    );
  }
};

export default ScheduleDialog;
