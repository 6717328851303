import moment from 'moment';
import {
    greyMedium,
    greyDark,
    greyUltraDark,
    greyLight,
    green,
    black,
    blue,
    yellow,
    orange,
    blueDark,
    brown
} from './colors';

export const WEEKLY = 0;
export const MONTHLY = 1;
export const YEARLY = 2;

export const LEGEND_COLORS = ["#5979EE", "#FFB53A", "#EA7D7D", "#53B565", "#AF6ACF", "#46BBAB", "#DADDFC", "#FC997C", "#b88d9d", greyLight, brown, greyDark, green, black, blue];

export const PHANTOM_LOAD_COLOR = greyUltraDark;

export function buildDay() {
    return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 4, y: 0 },
        { x: 5, y: 0 },
        { x: 6, y: 0 },
        { x: 7, y: 0 },
        { x: 8, y: 0 },
        { x: 9, y: 0 },
        { x: 10, y: 0 },
        { x: 11, y: 0 },
        { x: 12, y: 0 },
        { x: 13, y: 0 },
        { x: 14, y: 0 },
        { x: 15, y: 0 },
        { x: 16, y: 0 },
        { x: 17, y: 0 },
        { x: 18, y: 0 },
        { x: 19, y: 0 },
        { x: 20, y: 0 },
        { x: 21, y: 0 },
        { x: 22, y: 0 },
        { x: 23, y: 0 },
    ];
}

export function buildWeek() {
    return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 4, y: 0 },
        { x: 5, y: 0 },
        { x: 6, y: 0 },
    ];
}

export function buildMonth(monthDate) {
    return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
    ];
}

export function buildYear() {
    return [
        { x: 0, y: 0 },
        { x: 1, y: 0 },
        { x: 2, y: 0 },
        { x: 3, y: 0 },
        { x: 4, y: 0 },
        { x: 5, y: 0 },
        { x: 6, y: 0 },
        { x: 7, y: 0 },
        { x: 8, y: 0 },
        { x: 9, y: 0 },
        { x: 10, y: 0 },
        { x: 11, y: 0 },
    ];
}

export function buildWeekTicks(startDate) {
    const ticks = [];
    let currentDate = startDate;
    for (let i = 0; i < 7; i++) {
        if (i === 0) {
            ticks.push(currentDate.format('MMM Do'));
        } else {
            ticks.push(currentDate.format('Do'));
        }

        currentDate = currentDate.add(1, 'day');
    }

    return ticks;
}

export function buildMonthTicks(monthDate) {
    if (monthDate.daysInMonth() < 31) {
        return [
            "1st-8th",
            "9th-16th",
            "17th-24th",
            `25th-${monthDate.daysInMonth()}th`
        ]
    }
    else {
        return [
            "1st-8th",
            "9th-16th",
            "17th-24th",
            `25th-${monthDate.daysInMonth()}st`
        ]
    }
}

export function buildYearTicks() {
    return [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
}

function formatWeekDate(momentDate) {
    const [m, d, yr] = momentDate.format('MMM DD YYYY').split(' ');
    return `${m} ${d}, ${yr}`;
}

function formatMonthDate(momentDate) {
    return momentDate.format('MMM YYYY');
}

function formatYearDate(momentDate) {
    return momentDate.format('YYYY');
}

export function buildDailyDropdown(minDate) {
    const oneDayAgo = moment().startOf('day');
    console.log(minDate);
    let previousDay = oneDayAgo;

    const days = [];

    if (minDate) {
        const minDateMoment = moment(minDate)//.add(1, 'day');
        while (previousDay >= minDateMoment) {
            days.push(formatWeekDate(previousDay));
            previousDay = previousDay.subtract(1, 'day');
        }
    } else {
        days.push(formatWeekDate(previousDay));
        for (let i = 1; i < 30; i++) {
            previousDay = previousDay.subtract(1, 'week');
            days.push(formatWeekDate(previousDay));
        }
    }

    console.log(days)
    return days;
}

// a week's worth of data for the week beginning on the dropdown value
export function buildWeeklyDropdown(minDate, numWeeksAgo = 0) {
    const weeksAgo = moment().subtract(numWeeksAgo, 'week');
    const dayOfWeek = weeksAgo.isoWeekday();
    let previousSunday = weeksAgo;

    previousSunday = previousSunday.subtract(dayOfWeek - 1, 'days');

    const weeks = [];

    if (minDate) {
        const minDateMoment = moment(minDate);
        while (previousSunday >= minDateMoment) {
            weeks.push(formatWeekDate(previousSunday));
            previousSunday = previousSunday.subtract(1, 'week');
        }
    } else {
        weeks.push(formatWeekDate(previousSunday));
        for (let i = 1; i < 12; i++) {
            previousSunday = previousSunday.subtract(1, 'week');
            weeks.push(formatWeekDate(previousSunday));
        }
    }

    return weeks;
}

// a month's worth of data for the month beginning on the dropdown value
export function buildMonthlyDropdown(minDate) {
    let previousMonth = moment();
    console.log(previousMonth);
    const months = [];

    if (minDate) {
        const minDateMoment = moment(minDate);

        if (minDateMoment.year() < previousMonth.year()) {
            while (previousMonth.month() >= 1) {
                months.push(formatMonthDate(previousMonth));
                previousMonth = previousMonth.subtract(1, 'month');
            }
            months.push(formatMonthDate(previousMonth))
        }
        else {
            while (previousMonth.month() >= minDateMoment.month()) {
                if (previousMonth.month() == 0) {
                    months.push(formatMonthDate(previousMonth));
                    break;
                }
                months.push(formatMonthDate(previousMonth));
                previousMonth = previousMonth.subtract(1, 'month');
            }
        }

    } /*else {
    months.push(formatMonthDate(previousMonth));
    for (let i = 1; i < 12; i++) {
      previousMonth = previousMonth.subtract(1, 'month');
      months.push(formatMonthDate(previousMonth));
    }
  }*/

    return months;
}

// a year's worth of data for the year beginning on the dropdown value
export function buildYearlyDropdown(minDate) {
    let previousYear = moment();
    const years = [];

    if (minDate) {
        const minDateMoment = moment(minDate);
        while (previousYear.year() >= minDateMoment.year()) {
            years.push(formatYearDate(previousYear));
            previousYear = previousYear.subtract(1, 'year');
        }
    } else {
        years.push(formatYearDate(previousYear));
        for (let i = 1; i < 3; i++) {
            previousYear = previousYear.subtract(1, 'year');
            years.push(formatYearDate(previousYear));
        }
    }

    return years;
}

export function buildDayId(date) {
    return `${date.month()}_${date.date()}_${date.year()}`;
}

export function buildMonthId(date) {
    return `${date.month()}_${date.year()}`;
}

export function buildYearId(date) {
    return `${date.year()}`;
}
